import * as React from "react";
import { Provider as ReduxProvider } from "react-redux";
import configureStore from "rootStore";
import Routes from "routes/Router";
import Skeleton from 'components/dashboard/skeleton';
import { POST, setDefaultHeaders } from 'rest-client'
import dispatchHook from 'components/common/dispatchHook'
import { LOGIN_USER_SUCCESS } from 'reducers/LoginPage/constants'
import { SnackbarProvider } from 'notistack';
import apiendpoints from "utils/api-endpoints";


const { store } = configureStore({});

const Redux = React.memo(() => {
  return (
    <ReduxProvider store={store}>
      <SnackbarProvider autoHideDuration={4000} />
      <App />
    </ReduxProvider>
  );
})

const App = React.memo(() => {
  const [loggedIn, setLoggedIn] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [dispatch] = dispatchHook();

  async function init() {
    try {
      setLoggedIn(false)
      setLoading(true)
      const token = localStorage.getItem('x-access-token')
      if (token)
        setDefaultHeaders('x-access-token', token);
      if (!token) throw Error("No token found")
      const response = await POST(apiendpoints.INIT);
      const data = response.data.message;
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: data,
      });
      setLoggedIn(true);
    } catch (error) {
      console.log(error)
      localStorage.removeItem('x-access-token')
      setLoggedIn(false);
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    init()
  }, [])

  return (
    <>
      {loading ?
        <Skeleton />
        :
        <Routes isLoggedIn={loggedIn} setLoggedIn={setLoggedIn} />
      }
    </>
  )
})
export default Redux



