import React, { useState, useEffect } from 'react';
import { Container, Card, CardBody, Row, Col, CardTitle, CardSubtitle, Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { GET, PUT } from 'rest-client';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'reducers/LoginPage/reducer';
import apiEndpoints from 'utils/api-endpoints';
import female from "assets/images/users/user1.jpg";
import male from "assets/images/users/user4.jpg";

const MyGym = () => {
    const [myGym, setMyGym] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [gymTypes, setGymTypes] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        address: '',
        contact: '',
        type: ''
    });
    const [dropdownOpen, setDropdownOpen] = useState(false); // State for dropdown
    const loggedInUser = useSelector(getLoggedInUser);
    const gymId = loggedInUser?.my_gyms[0]?._id;

    useEffect(() => {
        if (gymId) {
            fetchMyGym();
            fetchGymTypes();
        }
    }, [gymId]);

    const fetchMyGym = async () => {
        try {
            const response = await GET(`${apiEndpoints.GYM}/${gymId}`);
            if (response?.data?.success) {
                setMyGym(response?.data?.message || null);
                setFormData({
                    name: response?.data?.message?.owner?.name || '',
                    address: response?.data?.message?.address || '',
                    contact: response?.data?.message?.owner?.pno || '',
                    type: response?.data?.message?.type || ''
                });
            } else {
                console.error('Error fetching gym details:', response?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching gym details:', error);
        }
    };

    const fetchGymTypes = async () => {
        try {
            const response = await GET(apiEndpoints.GYM_TYPES);
            if (response?.data?.success) {
                setGymTypes(response?.data?.message || []);
            } else {
                console.error('Error fetching gym types:', response?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching gym types:', error);
        }
    };

    const handleEditClick = () => setEditMode(true);
    const handleCancelClick = () => setEditMode(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleTypeChange = (type) => {
        setFormData({ ...formData, type });
        setDropdownOpen(false);
    };

    const handleUpdateClick = async () => {
        try {
            const response = await PUT(`${apiEndpoints.GYM}/${gymId}`, formData);
            if (response?.data?.success) {
                fetchMyGym();
                setEditMode(false); 
            } else {
                console.error('Error updating gym details:', response?.data?.message);
            }
        } catch (error) {
            console.error('Error updating gym details:', error);
        }
    };

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    return (
        <Container style={{ margin: '2% auto', maxWidth: '900px' }}>
            <h4 style={{ fontWeight: 'bold', fontSize: '1.5em', color: 'blue' }}>{myGym?.name}</h4>
            <Card body className="mb-4">
                <CardBody>
                    <Row className="align-items-center">
                        <Col xs="4" className="text-center">
                            <img
                                src={loggedInUser?.gender === "Female" ? female : male}
                                alt="profile"
                                className="rounded-circle"
                                width="120"
                            />
                        </Col>
                        <Col xs="8">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h4 style={{ color: 'green' }}>{myGym?.owner?.name}</h4>
                                    <CardSubtitle tag="h6" className="text-center text-muted">{myGym?.owner?.pno}</CardSubtitle>
                                    <p className="text-center"  style={{ color: myGym?.isActive ? 'green' : 'red', fontWeight: 'bold', fontSize: '1.1em' }}>
                                        {myGym?.isActive ? 'ACTIVE' : 'DEACTIVE'}
                                    </p>
                                </div>
                                {!editMode ? (
                                    <Button color="link" onClick={handleEditClick}>Edit</Button>
                                ) : (
                                    <div>
                                        <Button color="success" onClick={handleUpdateClick} style={{ marginRight: '10px' }}>Update</Button>
                                        <Button color="secondary" onClick={handleCancelClick}>Cancel</Button>
                                    </div>
                                )}
                            </div>
                            {editMode && (
                                <div>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formData?.name}
                                        onChange={handleInputChange}
                                        placeholder="Gym Name"
                                    />
                                    <Input
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        placeholder="Address"
                                    />
                                    <Input
                                        type="text"
                                        name="contact"
                                        value={formData.contact}
                                        onChange={handleInputChange}
                                        placeholder="Contact"
                                    />
                                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="dropdown-custom">
                                        <DropdownToggle caret>
                                            {formData.type || 'Select Type'}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {gymTypes.map((type) => (
                                                <DropdownItem
                                                    key={type}
                                                    onClick={() => handleTypeChange(type)}
                                                >
                                                    {type}
                                                </DropdownItem>
                                            ))}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card body className="mb-4">
                <CardBody>
                    <Row>
                        <Col style={{display: 'flex'}}>
                            <h5>Address  </h5>
                            <p>{"  :  "}{myGym?.address}</p>
                        </Col>
                        <Col style={{display: 'flex'}}>
                            <h5>Gym Contact</h5>
                            <p>{"  :  "}{myGym?.contact}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{display: 'flex'}}>
                            <h5>Manager Name</h5>
                            <p>{"  :  "}{myGym?.address}</p>
                        </Col>
                        <Col style={{display: 'flex'}}>
                            <h5>Manager Contact</h5>
                            <p>{"  :  "}{myGym?.contact}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{display: 'flex'}}>
                            <h5>Type</h5>
                            <p>{"  :  "}{myGym?.type}</p>
                        </Col>
                        <Col style={{display: 'flex'}}>
                            <h5>Membership Code</h5>
                            <p>{"  :  "}{myGym?.membership_code}</p>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </Container>
    );
};

export default MyGym;
