import { Spinner } from "reactstrap";

export const sidemenuItems = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: "bi bi-speedometer2",
  },
  {
    title: "Members",
    href: "/members",
    icon: "bi bi-person-add",
  },
  {
    title: "Attendance",
    href: "/attendance",
    icon: "bi bi-check-all",
  },
  {
    title: "Expenses",
    href: "/expenses",
    icon: "bi bi-currency-rupee",
  },
  {
    title: "Report",
    href: "/report",
    icon: "bi bi-file-earmark-pdf-fill",
  },
  {
    title: "My Account",
    href: "/MyAccount",
    icon: "bi bi-person-bounding-box",
  },
  {
    title: "Settings",
    href: "/settings",
    icon: "bi bi-gear",
  },
  {
    title: "About",
    href: "/about",
    icon: "bi bi-people",
  },
];

export const monthsForGraph = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]

export const optionsForGraph = {
  chart: {
    toolbar: {
      show: true,
    },
    stacked: false,
  },
  dataLabels: {
    enabled: true,
  },
  stroke: {
    show: true,
    width: 4,
    colors: ["transparent"],
  },
  legend: {
    show: true,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "30%",
      borderRadius: 2,
    },
  },
  colors: ["#0d6efd", "#009efb", "#6771dc"],
  xaxis: {
    categories: monthsForGraph,
  },
  responsive: [
    {
      breakpoint: 1024,
      options: {
        plotOptions: {
          bar: {
            columnWidth: "60%",
            borderRadius: 7,
          },
        },
      },
    },
  ],
};

export const membersTableColumns = [
  "MemberID",
  "Name",
  "Phone",
  "Date Of Expiry",
  "Total Amount",
  "Paid Amount",
  "Balance Amount",
  "Discount",
  "Batch",
  "Plan",
  "Gender",
  "Height",
  "Weight",
  "Address",
  "Date Of Join",
  "Mode Of Payment",
  "Actions"
]

export const loader = [
    <div className="d-flex justify-content-center align-items-center" 
         style={{ height: '100vh' , position: 'absolute', right:'50%', left:'50%'}}>
                <Spinner style={{ width: '2rem', height: '2rem' }} color="primary" />
           </div>
]

