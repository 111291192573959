import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardTitle, CardText, Button, Input, Form, FormGroup, Row, Col, Spinner } from 'reactstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { GET, POST } from 'rest-client';
import { getLoggedInUser } from 'reducers/LoginPage/reducer';
import { enqueueSnackbar } from 'notistack';
import apiendpoints from "utils/api-endpoints";
import { loader } from 'utils/constants';

const AttendancePage = () => {
    const { memberId } = useParams();
    const [userIdOrPhone, setUserIdOrPhone] = useState(memberId || '');
    const [userDetails, setUserDetails] = useState({});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const loggedInUser = useSelector(getLoggedInUser);
    const [loading, setLoading] = useState(false);
    const gymId = loggedInUser?.my_gyms[0]?._id;

    const convertToIST = (utcDate) => {
        if (!utcDate) return null;
        const date = new Date(utcDate);
        const utcHours = date.getUTCHours();
        const utcMinutes = date.getUTCMinutes();
        let istHours = utcHours + 5;
        let istMinutes = utcMinutes + 30;

        if (istMinutes >= 60) {
            istMinutes -= 60;
            istHours += 1;
        }
        if (istHours >= 24) {
            istHours -= 24;
        }
        const period = istHours >= 12 ? 'PM' : 'AM';
        istHours = istHours % 12 || 12;
        const formattedTime = `${istHours.toString().padStart(2, '0')}:${istMinutes.toString().padStart(2, '0')} ${period}`;
        return formattedTime;
    };

    const fetchUserDetails = async (inputValue) => {
        setLoading(true)
        try {
            const response = await GET(`${apiendpoints.REGISTER}${inputValue}`);
            const data = response?.data?.message;

            if (data) {
                const activePlan = data?.active_membership?.plan || {};

                const checkInTimeIST = convertToIST(data?.checkInTime);
                const checkOutTimeIST = convertToIST(data?.checkOutTime);

                const expiryDate = data?.active_membership?.endDate ? new Date(data?.active_membership?.endDate) : null;
                const currentTime = new Date();
                const disableButton = checkInTimeIST && checkOutTimeIST;

                const expiryDateStyle = expiryDate && (expiryDate - currentTime) / (1000 * 60 * 60 * 24) <= 10 ? 'text-danger' : 'text-success';

                setIsButtonDisabled(disableButton);

                setUserDetails({
                    userid: data._id,
                    id: data?.active_membership?.memberId || '',
                    name: data?.name || '',
                    img: data?.profile_url || '',
                    phone: data?.pno ? `******${data?.pno.slice(-4)}` : '',
                    plan_name: activePlan?.name || '',
                    joinedDate: data?.active_membership?.startDate ? new Date(data?.active_membership?.startDate).toLocaleDateString() : '',
                    expiryDate: expiryDate ? expiryDate.toLocaleDateString() : '',
                    expiryDateStyle: expiryDateStyle,
                    balance: data?.active_membership?.pending || 0,
                    checkInTime: checkInTimeIST,
                    checkOutTime: checkOutTimeIST,
                    isLoggedIn: checkInTimeIST && !checkOutTimeIST,
                    duration: data?.duration || '',
                    time_spent_min: data.time_spent_min
                });

            } else {
                setUserDetails({});
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUserDetails({});
            enqueueSnackbar('Entered Member ID/Phone Number is incorrect!', { variant: 'info' });
        }
        setLoading(false)
    };


    const handleFetchUser = (e) => {
        setLoading(true)
        e.preventDefault();
        if (userIdOrPhone) {
            fetchUserDetails(userIdOrPhone.toUpperCase());
        }
        setLoading(false)
    };

    const handleClearInput = () => {
        setUserIdOrPhone('');
        setUserDetails({});
    };

    const handleSubmitAttendance = async () => {
        setLoading(true)
        const requestData = {
            user_id: userDetails.userid,
            gym_id: gymId,
        };

        try {
            const response = await POST(apiendpoints.ATTENDANCE, requestData);
            const timeSpentMin = response.data?.message?.time_spent_min || '';

            if (userDetails.checkInTime && !userDetails.checkOutTime) {

                enqueueSnackbar('Logout successful!', { variant: 'info' });
                fetchUserDetails(userIdOrPhone);
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    duration: timeSpentMin
                }));
                setTimeout(() => {
                    setUserDetails({});
                    setUserIdOrPhone('');
                }, 10000);
            } else {
                enqueueSnackbar('Login successful!', { variant: 'success' });
                setTimeout(() => {
                    setUserDetails({});
                    setUserIdOrPhone('');
                }, 5000)
            }
            setShowConfirm(false);
        } catch (error) {
            console.error("There was an error submitting the attendance!", error);
        }
        setLoading(false)
    };

    // if (loading) {
    //     return (
    //         <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
    //             <Spinner style={{ width: '2rem', height: '2rem' }} color="primary" />
    //         </div>
    //     );
    // }

    return (
        <div className="container mt-5">
            <h3>Mark Attendance</h3>
            <Form>
                <FormGroup>
                    <Row form>
                        <Col md={5}>
                            <Input
                                type="text"
                                name="userIdOrPhone"
                                id="userIdOrPhone"
                                value={userIdOrPhone}
                                onChange={(e) => setUserIdOrPhone(e.target.value)}
                                placeholder="Enter Member ID/Phone Number"
                                className="custom-input-width"
                            />
                        </Col>
                        <Col md={4}>
                            <Button type="submit" color="primary" className="w-100" onClick={handleFetchUser}>Fetch Member Details</Button>
                        </Col>
                        <Col md={3}>
                            <Button color="secondary" className="w-100" onClick={handleClearInput}>Clear</Button>
                        </Col>
                    </Row>
                </FormGroup>
            </Form>
            <div>
                {Object.keys(userDetails).length > 0 && userDetails.id ? (
                    <Card className="mt-4">
                        <CardBody>
                            <div style={{ position: 'relative' }}>
                                {showConfirm ? (
                                    <>
                                        <Button
                                            color="secondary"
                                            onClick={() => setShowConfirm(false)}
                                            style={{ position: 'absolute', top: 0, right: 0 }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="danger"
                                            onClick={handleSubmitAttendance}
                                            style={{ position: 'absolute', top: 0, right: 80 }}
                                        >
                                            Are You Sure?
                                        </Button>

                                    </>
                                ) : (
                                    <>
                                        {!userDetails.checkInTime && !userDetails.checkOutTime && (
                                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                                <Button
                                                    color="primary"
                                                    onClick={handleSubmitAttendance}
                                                >
                                                    Login
                                                </Button>
                                            </div>
                                        )}
                                        {userDetails.checkInTime && !userDetails.checkOutTime && (
                                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                                <Button
                                                    color="danger"
                                                    onClick={() => setShowConfirm(true)}
                                                >
                                                    Logout
                                                </Button>
                                            </div>
                                        )}
                                        {userDetails.checkInTime && userDetails.checkOutTime && (
                                            <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                                {/* No button */}
                                            </div>
                                        )}
                                    </>
                                )}
                                <CardTitle tag="h5" className="text-primary mb-3">Member Details</CardTitle>
                                <hr />
                                {loading ? (
                                 loader
                            ) : (
                                <>
                                {userDetails.time_spent_min && (
                                     <CardText className="d-flex justify-content-center">
                                     <span><strong color='success'> You have Spent {userDetails.time_spent_min || '0'} minutes!</strong></span>
                                 </CardText>
                                )
                            }
                                <CardText>
                                    <strong style={{ fontWeight: 'bold', fontSize: '1.1em' }}>{userDetails.name}</strong>
                                </CardText>
                                <CardText className="d-flex justify-content-between">
                                    <span><strong>Member ID:</strong> {userDetails.id}</span>
                                    <span><strong>Balance:</strong><strong style={{ fontWeight: 'bold', fontSize: '1em', color: 'Highlight' }}> {userDetails.balance}</strong></span>
                                </CardText>
                                <CardText className="d-flex justify-content-between">
                                    <span><strong>Expiry Date:</strong> <strong className={userDetails.expiryDateStyle}> {userDetails.expiryDate}</strong></span>
                                    <span><strong>Joined Date:</strong> {userDetails.joinedDate}</span>
                                </CardText>
                                <CardText className="d-flex justify-content-between">
                                    <span><strong>Plan:</strong> {userDetails.plan_name}</span>
                                    <span><strong>Phone:</strong> {userDetails.phone}</span>
                                </CardText>
                                <CardText className="d-flex justify-content-between">
                                    <span><strong>Check-In Time:</strong> {userDetails.checkInTime || '--'}</span>
                                    <span><strong>Check-Out Time:</strong> {userDetails.checkOutTime || '--'}</span>
                                </CardText>
                                </>
                            )}
                            </div>
                        </CardBody>
                    </Card>
                ) : (
                    <Card className="mt-4">
                        <CardBody>
                            <CardText className="text-center">No member details found. Please fetch the member details.</CardText>
                        </CardBody>
                    </Card>
                )}
            </div>
        </div>
    );
};

export default AttendancePage;
