import React from "react";
import { Card, CardBody, CardTitle, CardSubtitle, Table } from "reactstrap";
import male from "../../assets/images/users/user5.jpg";
import female from "../../assets/images/users/user3.jpg";
import { useNavigate } from "react-router-dom";

const ProjectTables = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Card>
        <CardBody>
          <CardTitle tag="h5">Recent Admissions</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            Overview of Recent Members
          </CardSubtitle>

          <Table className="no-wrap mt-3 align-middle" responsive borderless>
            <thead>
              <tr>
                <th>Name & Address</th>
                <th>Phone Number</th>
                <th>Plan</th>
                <th>Total Amount</th>
                <th>Date Of Admission</th>
                <th>Pending</th>
              </tr>
            </thead>
            <tbody>
              {data?.sortedByRecentAdditions?.map((tdata, index) => (
                <tr key={index} className="border-top">
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/EditUser/${tdata?.user?._id}`)}
                  >
                    <div className="d-flex align-items-center p-2">
                      <img
                        src={tdata?.user?.gender === 'Female' ? female : male}
                        className="rounded-circle"
                        alt="avatar"
                        width="45"
                        height="45"
                      />
                      <div className="ms-3">
                        <h6 style={{color: 'blue', textDecoration: 'underline'}} className="mb-0">{tdata?.user?.name}</h6>
                        <span className="text-muted">{tdata?.user?.address}</span>
                      </div>
                    </div>
                  </td>
                  <td>{tdata?.user?.pno}</td>
                  <td>{tdata?.plan?.name}</td>
                  <td>{tdata?.plan?.price}</td>
                  <td>{new Date(tdata.createdAt).toLocaleDateString()}</td>
                  <td style={{color: 'red'}}>{tdata?.pending}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProjectTables;
