import React from 'react';
import { Container, Row, Col } from 'reactstrap';
// import './SkeletonComp.css'; // Import the CSS file for custom styling

export default function SkeletonComp() {
    return (
        <Container>
            <Row>
                <Col xs="auto" className="skeleton-text"></Col>
                <Col xs="auto" className="skeleton-circle"></Col>
            </Row>
            <div className="skeleton-rectangular-large"></div>
            <Row className="mt-4">
                <Col xs="3" className="skeleton-rectangular-small"></Col>
                <Col xs="3" className="skeleton-rectangular-small"></Col>
                <Col xs="3" className="skeleton-rectangular-small"></Col>
                <Col xs="3" className="skeleton-rectangular-small"></Col>
            </Row>
            <div className="skeleton-rectangular-small mt-4"></div>
            <div className="skeleton-rectangular-medium mt-4"></div>
            <div className="skeleton-rectangular-small mt-4"></div>
        </Container>
    );
}