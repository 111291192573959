import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default () => {
    const dispatch = useDispatch()
    const dispatchAction = (action) => {
        dispatch(action)
    }
    const getStateData = (action) => {
        return action
    }
    return [dispatchAction, getStateData]
}