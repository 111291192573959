import { lazy } from "react";
// import { Navigate, Routes } from "react-router-dom";

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import SkeletonComp from 'components/dashboard/skeleton';

/****Layouts*****/
import FullLayout from  "../layouts/FullLayout.js"

/***** Pages ****/

import Starter from  "../views/Starter.js"
import About from  "../views/About.js"
import Alerts from  "../views/ui/Alerts"
import Badges from  "../views/ui/Badges"
import Buttons from  "../views/ui/Buttons"
import Cards from  "../views/ui/Cards"
import Grid from  "../views/ui/Grid"
import Tables from  "../views/ui/Tables"
import Forms from  "../views/ui/Forms"
import Register from  "../views/members/Register"
import Breadcrumbs from  "../views/ui/Breadcrumbs"
import Login from  "../views/authentication/login"
import Members from  "../views/members/Members.js"
import Attendance from  "../views/members/Attendance.js"
import Report from  "../views/members/Report.js"
import Expenses from  "../views/members/Expenses.js"
import MyAccount from  "../views/members/MyAccount.js"
import Settings from  "../views/settings.js"
import EditUser from  "../views/members/EditUser.js"
import AttendanceHistory from  "../views/members/AttendanceHistory.js"
import RecentLogins from "../views/members/RecentLogins.js"
import MyGym from "views/myGym.js";

const AppRoutes = ({ isLoggedIn, setLoggedIn }) => {
  return (
    <Router>
      {/* <ScrollToTop> */}
        {/* <Suspense fallback={<SkeletonComp />}> */}
          <Routes>
          <Route path="/login" element={!isLoggedIn ? <Login setLoggedIn={setLoggedIn}/> : <Navigate replace to="/" />} />
          <Route path="/" element={isLoggedIn ? <FullLayout /> : <Navigate replace to="/login" />}>
              {/* <Route index key={isLoggedIn} element={isLoggedIn ? <Starter /> : <Navigate replace to="/login" />} /> */}
              <Route path="/" element={<Starter />} />
              <Route path="/about" element={<About />} />
              <Route path="/alerts" element={<Alerts />} />
              <Route path="/badges" element={<Badges />} />
              <Route path="/buttons" element={<Buttons />} />
              <Route path="/cards" element={<Cards />} />
              <Route path="/grid" element={<Grid />} />
              <Route path="/table" element={<Tables />} />
              <Route path="/forms" element={<Forms />} />
              <Route path="/breadcrumbs" element={<Breadcrumbs />} />
              <Route path="/register" element={<Register />} />
              <Route path="/Members" element={<Members />} />
              <Route path="/Attendance/" element={<Attendance />} />
              <Route path="/Attendance/:memberId" element={<Attendance />} />
              <Route path="/Report" element={<Report />} />
              <Route path="/Expenses" element={<Expenses />} />
              <Route path="/MyAccount" element={<MyAccount />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/EditUser/:memberId" element={<EditUser/>}/>
              <Route path="/attendance-history/:memberId" element={<AttendanceHistory/>}/>
              <Route path="/recentlogins" element={<RecentLogins />} />
              <Route path="/myGym" element={<MyGym />} />
            </Route>
            <Route path="*" element={<Navigate replace to={isLoggedIn ? "/" : "/login"} />} />
          </Routes>
        {/* </Suspense> */}
      {/* </ScrollToTop> */}
    </Router >
  );
}
export default AppRoutes
