import React, { useState } from 'react';
import {
    Form, Input, Col, Row, Button,
    AccordionItem, AccordionHeader, AccordionBody, Accordion
} from 'reactstrap';

const MembershipDetails = ({
    plans, selectedPlan, amount, personalTraining,
    setPersonalTraining, TimeSlot, joinDate, discount, setTimeSlot, setJoinDate,
    setDiscount, isEditMode, isActive, handlePlanChange,
    amountPaid, paymentMode, totalAmount, batch,
    setAmountPaid, setPaymentMode, handleUpdate, paymentType, setIsEditMode
}) => {
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [activeAccordion, setActiveAccordion] = useState('1');

    const addPaymentDetail = () => {
        setPaymentDetails([...paymentDetails, { amountPaid: '', paymentMode: '' }]);
        setActiveAccordion((prev) => (paymentDetails.length + 2).toString());
    };

    const handleCancel = (index) => {
        const updatedDetails = paymentDetails.filter((_, i) => i !== index);
        setPaymentDetails(updatedDetails);
        setActiveAccordion((updatedDetails.length > 0 ? Math.min(index + 1, updatedDetails.length + 2) : '1').toString());
        setIsEditMode(false);
    };

    return (
        <Accordion open={activeAccordion} toggle={setActiveAccordion}>
            <AccordionItem>
                <AccordionHeader targetId="1" id>
                    Membership Details
                </AccordionHeader>
                <AccordionBody accordionId="1">
                    <Form>
                        {/* Membership Plan */}
                        <Row className="mb-3">
                            <Col sm={2}>Plan</Col>
                            <Col sm={10}>
                                <Input
                                    type="select"
                                    name="plan"
                                    id="plan"
                                    value={selectedPlan}
                                    onChange={handlePlanChange}
                                    disabled={!isEditMode || isActive}
                                >
                                    <option value="">Select a plan</option>
                                    {plans.map((plan) => (
                                        <option key={plan._id} value={plan._id}>
                                            {`${plan.name} - ${plan.price}`}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>

                        {/* Amount */}
                        <Row className="mb-3">
                            <Col sm={2}>Amount</Col>
                            <Col sm={10}>
                                <Input
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    value={amount}
                                    disabled={!isEditMode}
                                    readOnly
                                />
                            </Col>
                        </Row>

                        {/* Personal Training */}
                        <Row className="mb-3">
                            <Col sm={2}>Personal Training</Col>
                            <Col sm={10}>
                                <div className="d-flex">
                                    <Input
                                        type="radio"
                                        name="personalTraining"
                                        value="yes"
                                        checked={personalTraining === 'yes'}
                                        onChange={(e) => setPersonalTraining(e.target.value)}
                                        disabled={!isEditMode}
                                        className="me-2"
                                    />{' '}
                                    Yes
                                    <Input
                                        type="radio"
                                        name="personalTraining"
                                        value="no"
                                        checked={personalTraining === 'no'}
                                        onChange={(e) => setPersonalTraining(e.target.value)}
                                        disabled={!isEditMode}
                                        className="ms-4 me-2"
                                    />{' '}
                                    No
                                </div>
                            </Col>
                        </Row>

                        {/* Time Slot */}
                        <Row className="mb-3">
                            <Col sm={2}>Time Slot</Col>
                            <Col sm={10}>
                                <Input
                                    type="select"
                                    name="timeSlot"
                                    id="timeSlot"
                                    value={TimeSlot || batch}
                                    onChange={(e) => setTimeSlot(e.target.value)}
                                    disabled={!isEditMode || isActive}
                                >
                                    <option value="">Select a time slot</option>
                                    <option value="morning">Morning</option>
                                    <option value="evening">Evening</option>
                                </Input>
                            </Col>
                        </Row>

                        {/* Join Date */}
                        <Row className="mb-3">
                            <Col sm={2}>Join Date</Col>
                            <Col sm={10}>
                                <Input
                                    type="date"
                                    name="joinDate"
                                    id="joinDate"
                                    value={joinDate}
                                    onChange={(e) => setJoinDate(e.target.value)}
                                    disabled={!isEditMode || isActive}
                                />
                            </Col>
                        </Row>

                        {/* Discount */}
                        <Row className="mb-3">
                            <Col sm={2}>Discount</Col>
                            <Col sm={10}>
                                <Input
                                    type="number"
                                    name="discount"
                                    id="discount"
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                    disabled={!isEditMode}
                                />
                            </Col>
                        </Row>
                    </Form>
                </AccordionBody>
            </AccordionItem>

            <AccordionItem>
                <AccordionHeader targetId="2">
                    Payment Details
                </AccordionHeader>
                <AccordionBody accordionId="2">
                    <Form>
                        {/* Payment Details */}
                        <Row className="mb-3">
                            <Col sm={2}>Total Amount</Col>
                            <Col sm={10}>
                                <Input
                                    type="text"
                                    name="totalAmount"
                                    id="totalAmount"
                                    value={totalAmount}
                                    readOnly
                                    className="green-shadow"
                                    disabled={!isEditMode || isActive}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2}>Payment Mode</Col>
                            <Col sm={10}>
                                <Input
                                    type="select"
                                    name="paymentMode"
                                    id="paymentMode"
                                    value={paymentMode || paymentType}
                                    onChange={(e) => setPaymentMode(e.target.value)}
                                    disabled={!isEditMode || isActive}
                                >
                                    <option value="">Select payment mode</option>
                                    <option value="cash">Cash</option>
                                    <option value="upi">UPI</option>
                                </Input>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={2}>Amount Paid</Col>
                            <Col sm={10}>
                                <Input
                                    type="text"
                                    name="amountPaid"
                                    id="amountPaid"
                                    value={amountPaid}
                                    onChange={(e) => setAmountPaid(e.target.value)}
                                    disabled={!isEditMode || isActive}
                                />
                            </Col>
                        </Row>
                        {/* Buttons */}
                        {isEditMode && (
                            <Button
                                color="primary"
                                size="sm"
                                className="ms-auto"
                                onClick={addPaymentDetail}
                            >
                                + Add Payment
                            </Button>
                        )}
                    </Form>
                </AccordionBody>
            </AccordionItem>

            {/* Dynamic Payment Detail Accordions */}
            {paymentDetails.map((paymentDetail, index) => (
                <AccordionItem key={index}>
                    <AccordionHeader targetId={(index + 3).toString()}>
                        Payment Detail {index + 1}
                    </AccordionHeader>
                    <AccordionBody accordionId={(index + 3).toString()}>
                        <Form>
                            <Row className="mb-3">
                                <Col sm={2}>Amount Paid</Col>
                                <Col sm={10}>
                                    <Input
                                        type="text"
                                        name={`amountPaid${index}`}
                                        id={`amountPaid${index}`}
                                        value={paymentDetail.amountPaid}
                                        onChange={(e) => {
                                            const updatedDetails = [...paymentDetails];
                                            updatedDetails[index].amountPaid = e.target.value;
                                            setPaymentDetails(updatedDetails);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={2}>Payment Mode</Col>
                                <Col sm={10}>
                                    <Input
                                        type="select"
                                        name={`paymentMode${index}`}
                                        id={`paymentMode${index}`}
                                        value={paymentDetail.paymentMode}
                                        onChange={(e) => {
                                            const updatedDetails = [...paymentDetails];
                                            updatedDetails[index].paymentMode = e.target.value;
                                            setPaymentDetails(updatedDetails);
                                        }}
                                    >
                                        <option value="">Select payment mode</option>
                                        <option value="cash">Cash</option>
                                        <option value="upi">UPI</option>
                                    </Input>
                                </Col>
                            </Row>
                        </Form>
                        {isEditMode && (
                        <Row className="mb-3">
                            <Col sm={12}>
                                <Button
                                    color="primary"
                                    onClick={handleUpdate}
                                    className="me-2"
                                >
                                    Update
                                </Button>
                                <Button
                                    color="secondary"
                                    onClick={() => handleCancel(index)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    )}
                    </AccordionBody>
                </AccordionItem>
            ))}
        </Accordion>
    );
};

export default MembershipDetails;
