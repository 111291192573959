import { Card, CardBody, CardSubtitle, CardTitle, Row, Col, CardText, Button } from "reactstrap";
import Chart from "react-apexcharts";
import { monthsForGraph, optionsForGraph } from "utils/constants";
import { useEffect, useState } from "react";

const SalesChart = ({data}) => {
  const [graphSeries, setGraphSeries] = useState([
    {
      name: "2024",
      data: [10, 20, 40, 60, 20, 40, 60, 60, 20],
    },
  ])
  useEffect(() => {
    const moonthlyMap = {}
    data?.monthlyAdmissions?.forEach(monthly => {
      moonthlyMap[monthly?._id?.month] = monthly.count
    })
    setGraphSeries([{
      name: "2024",
      data: monthsForGraph.map((_, index) => {
        if(!moonthlyMap[index+1]) return 0
        return moonthlyMap[index+1]
      }) || [],
    }])
  }, [data?.monthlyAdmissions])

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Admission Summary</CardTitle>
        <CardSubtitle className="text-muted" tag="h6">
          Yearly Admission Report
        </CardSubtitle>
        <Chart options={optionsForGraph} series={graphSeries} type="bar" height="379" />
      </CardBody>
    </Card>
  );
};

export default SalesChart;
