import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundImage from 'assets/images/bg/backgroundLogin.jpg';
import { POST, setDefaultHeaders } from 'rest-client'
import { LOGIN_USER_SUCCESS } from 'reducers/LoginPage/constants'
import Logo from 'layouts/Logo';
import apiendpoints from "utils/api-endpoints";

const LoginWithPhone = ({setLoggedIn}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [modal, setModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toggle = () => {
    setModal(!modal);
    if (!modal) setOtp('');
  };

  const handleSendOtp = async () => {
    const phoneRegex = /^\d{10}$/;

    if (!phoneRegex.test(phoneNumber)) {
      setErrorMessage('Please enter a valid 10-digit phone number');
      return;
    }

    try {
      // await login({
      //   pno: phoneNumber,
      //   "otpv-verification": true,
      // });
      setIsOtpSent(true);
      toggle();
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to send OTP';
      setErrorMessage(errorMessage);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const result = await POST(apiendpoints.LOGIN, {
        pno: phoneNumber,
        "otpv-verification": true,
      })
      const data = result?.data?.message;
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: data,
      });
      setDefaultHeaders("x-access-token", result?.data?.message?.token)
      localStorage.setItem('x-access-token', result?.data?.message?.token)

      setSuccessMessage('Authentication successful');
      toggle();
      setLoggedIn(true)
      navigate('/')
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to verify OTP';
      setErrorMessage(errorMessage);
      setOtp('');
    }
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid 10-digit phone number');
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
      }}
    >
      <Card className="w-20" style={{ maxWidth: '400px', textAlign: 'center', padding: '20px' }}>
          <Logo />
        <CardBody>
          <CardTitle tag="h3">Welcome to Namma Fit</CardTitle>
          <CardText style={{marginTop: '7%'}}>
            <Form>
              <FormGroup row>
                <Col sm={12}>
                  <Input
                    type="tel"
                    name="phone"
                    id="phone"
                    placeholder="Enter your 10-digit phone number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    pattern="^\d{10}$"
                    required
                  />
                </Col>
              </FormGroup>
              {errorMessage && <p className="text-danger">{errorMessage}</p>}
              {successMessage && <p className="text-success">{successMessage}</p>}
              <Button color="primary" onClick={handleSendOtp} style={{ display: 'block', margin: '0 auto' }}>
                Send OTP
              </Button>

              <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Enter OTP</ModalHeader>
                <ModalBody>
                  <FormGroup>
                    <Label for="otp">OTP</Label>
                    <Input
                      type="text"
                      name="otp"
                      id="otp"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={handleVerifyOtp}>
                    Verify
                  </Button>{' '}
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </Form>
          </CardText>
        </CardBody>
      </Card>
    </div>
  );
};

export default LoginWithPhone;
