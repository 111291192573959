import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

const Breadcrumbs = () => {
  const location = useLocation()
  const [path, setPath] = useState("")
  useEffect(() => setPath(location.pathname),  [location])
  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <Link to="/dashboard">HOME</Link>
      </BreadcrumbItem>
      {path !== '/dashboard' && <BreadcrumbItem active>{path?.slice(1)?.toUpperCase()}</BreadcrumbItem>}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
