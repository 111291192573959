import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { getLoggedInUser } from 'reducers/LoginPage/reducer';
import { activeLocation } from 'reducers/DashBoard/reducer';
import female from "assets/images/users/user1.jpg";
import male from "assets/images/users/user4.jpg";

function Settings() {
    const router = useNavigate();
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getLoggedInUser);
    const location = useSelector(activeLocation);

    const [modal, setModal] = React.useState(false);
    const [modalContent, setModalContent] = React.useState('');

    const toggleModal = (content = '') => {
        setModalContent(content);
        setModal(!modal);
    };

    return (
        <Container style={{ margin: '2% auto', maxWidth: '800px' }}>
            <h4 style={{ color: 'green' }}>Settings</h4>
            <Card body className="mb-4">
                <CardBody>
                    <Row className="align-items-center">
                        <Col xs="4" className="text-center">
                            <img
                                src={loggedInUser.gender === "Female" ? female : male}
                                alt="profile"
                                className="rounded-circle"
                                width="120"
                            />
                        </Col>
                        <Col xs="8">
                            <CardTitle tag="h4" className="text-center" style={{ color: 'green' }}>{loggedInUser?.name}</CardTitle>
                            <CardSubtitle tag="h6" className="text-center text-muted">{loggedInUser?.pno}</CardSubtitle>
                            <br/>
                            <CardSubtitle tag="h5" className="text-center" style={{ color: 'blue' }}>{loggedInUser?.my_gyms[0].name}</CardSubtitle>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card body className="mb-4">
                <Row>
                    <Col>
                    <Button color="link" style={{ color: 'green' }}>Notifications</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button color="link" style={{ color: 'green' }} onClick={() => toggleModal('https://firebasestorage.googleapis.com/v0/b/step-counter-999fe.appspot.com/o/images%2Fpublic%2Fpdf%2FTerms%20And%20Conditions.pdf?alt=media&token=ff61794a-c5e7-47b0-94b5-9b72197f2dc1')}>Terms and Conditions</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button color="link" style={{ color: 'green' }} onClick={() => toggleModal('https://firebasestorage.googleapis.com/v0/b/step-counter-999fe.appspot.com/o/images%2Fpublic%2Fpdf%2FAboutus.pdf?alt=media&token=b0b7dd17-d1ca-423d-b634-c34d067104ba')}>About Us</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button color="link" style={{ color: 'green' }} onClick={() => toggleModal('https://firebasestorage.googleapis.com/v0/b/step-counter-999fe.appspot.com/o/images%2Fpublic%2Fpdf%2FHelp%20and%20Support.pdf?alt=media&token=326796ad-67fd-49e5-bb3c-7ec0c5eca056')}>Help & Support</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button color="link" style={{ color: 'green' }} onClick={() => router('/privacypolicy')}>Privacy Policy</Button>
                    </Col>
                </Row>
            </Card>

            <Card body className="mb-4">
                <Button color="danger" onClick={() => {
                    localStorage.removeItem('x-access-token');
                    window.location = "/login";
                }} style={{ width: '100%' }}>
                    Logout
                </Button>
            </Card>

            <Row>
                <Col className="text-center">
                    <span className="text-secondary">Copyright @{new Date().getFullYear()}</span>
                </Col>
            </Row>

            <Modal isOpen={modal} toggle={() => toggleModal()}>
                <ModalBody>
                    <iframe src={modalContent} title="Document" style={{ width: '100%', height: '500px' }}></iframe>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => toggleModal()}>Close</Button>
                </ModalFooter>
            </Modal>
        </Container>
    );
}

export default Settings;
