import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Container } from "reactstrap";
import Breadcrumbs from "../views/ui/Breadcrumbs";


const FullLayout = () => {
  const location = useLocation();
  const hideSidebarRoutes = ["/login"]; 
  const hideBreadcrumbsRoutes = ["/login"]; 

  return (
    <main>
      {/********header**********/}
      <Header />
      <div className={`pageWrapper ${hideSidebarRoutes.includes(location.pathname) ? "" : "d-lg-flex"}`} style={{paddingTop: '60px'}}>
        {/********Sidebar**********/}
        {!hideSidebarRoutes.includes(location.pathname) && (
          <aside className="sidebarArea shadow" id="sidebarArea">
            <Sidebar />
          </aside>
        )}
        {/********Content Area**********/}
        <div className="contentArea">
          {/********Middle Content**********/}
          <Container className="p-4" fluid>
            {!hideBreadcrumbsRoutes.includes(location.pathname) && <Breadcrumbs />}
            <Outlet />
          </Container>
        </div>
      </div>
    </main>
  );
};

export default FullLayout;
