import React, { useState, useEffect } from 'react';
import { Table, Container } from 'reactstrap';
import { GET } from 'rest-client';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'reducers/LoginPage/reducer';
import apiEndpoints from 'utils/api-endpoints';
import { useParams } from 'react-router-dom';
import { loader } from 'utils/constants';

const MembershipHistory = () => {
    const { memberId } = useParams();
    const [membershipHistory, setMembershipHistory] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const loggedInUser = useSelector(getLoggedInUser);
    const gymId = loggedInUser?.my_gyms[0]?._id;
    const itemsPerPage = 10; 
    const pageNo = 1; 

    useEffect(() => {
        if (gymId && memberId) {
            fetchMembershipHistory();
        }
    }, [gymId, memberId]);

    const fetchMembershipHistory = async () => {
        try {
            const response = await GET(`${apiEndpoints.MEMBERSHIPHISTORY}?memberId=${memberId}&gymId=${gymId}&pageSize=${itemsPerPage}&pageIndex=${pageNo}`);
            if (response?.data?.success) {
                setMembershipHistory(response?.data?.message || []);
            } else {
                console.error('Error fetching membership history:', response?.data?.message);
            }
        } catch (error) {
            console.error('Error fetching membership history:', error);
        }
    };

    return (
        <Container>
            <h3 className="mb-3">Membership History</h3>
            {isLoading ? (
                loader
            ) : (
                <>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Plan</th>
                                <th>Start Date</th>
                                <th>Expiry Date</th>
                                <th>Plan Amout</th>
                                <th>Discount</th>
                                <th>Total Amount</th>
                                <th>Paid Amout</th>
                                <th>Balance</th>
                                <th>Payment Mode</th>
                            </tr>
                        </thead>
                        <tbody>
                            {membershipHistory.length > 0 ? (
                                membershipHistory.map((item) => (
                                    <tr key={item._id}>
                                        <td>{item.plan?.name || '--'}</td>
                                        <td>{new Date(item.startDate).toLocaleDateString() || '--'}</td>
                                        <td>{new Date(item.endDate).toLocaleDateString() || '--'}</td>
                                        <td>{item.plan?.price || '--'}</td>
                                        <td>{item.discount || '--'}</td>
                                        <td>{item.plan?.price - item.discount || '--'}</td>
                                        <td>{item.payment?.pricePaid || '--'}</td>
                                        <td>{item.pending || '--'}</td>
                                        <td>{item.payment?.receivedType || '--'}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="text-center">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </>
            )}
        </Container>
    );
};

export default MembershipHistory;
