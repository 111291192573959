import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
} from "reactstrap";
import Logo from "./Logo";
import { ReactComponent as LogoWhite } from "../assets/images/logos/materialprowhite.svg";
import female from "../assets/images/users/user1.jpg";
import male from "../assets/images/users/user4.jpg";
import { getLoggedInUser } from "reducers/LoginPage/reducer";

const Header = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const navigate = useNavigate();
  const loggedInUser = useSelector(getLoggedInUser)
  const gymName = loggedInUser?.my_gyms[0].name;

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => setIsOpen(!isOpen);
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };

  const handleLogout = () => {
    navigate('/#/login');
  };

  return (
    <Navbar color="primary" dark expand="md" className="fix-header w-100" style={{position: 'fixed', zIndex: 999}}>
      <div className="d-flex align-items-center">
        <div className="d-lg-block d-none me-5 pe-3" style={{marginLeft: '10px'}}>
          <Logo />
        </div>
        <NavbarBrand href="/">
          <LogoWhite className="d-lg-none" />
        </NavbarBrand>
        <Button
          color="primary"
          className="d-lg-none"
          onClick={showMobilemenu}
        >
          <i className="bi bi-list"></i>
        </Button>
      </div>
      <div className="hstack gap-2">
        <Button
          color="primary"
          size="sm"
          className="d-sm-block d-md-none"
          onClick={Handletoggle}
        >
          {isOpen ? (
            <i className="bi bi-x"></i>
          ) : (
            <i className="bi bi-three-dots-vertical"></i>
          )}
        </Button>
      </div>

      <Collapse navbar isOpen={isOpen}>
        <Nav className="me-auto" navbar>
          <NavItem>
            <Link to="/starter" className="nav-link">
              Dashboard
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/register" className="nav-link">
              Register
            </Link>
          </NavItem>
          <UncontrolledDropdown inNavbar nav>
            <DropdownToggle caret nav>
              Attendance
            </DropdownToggle>
            <DropdownMenu end>
            <DropdownItem  onClick={() =>navigate(`/recentlogins`)}>View Attendance</DropdownItem>
              <DropdownItem  onClick={() =>navigate(`/Attendance`)}>Submit Attendance</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <div className="d-flex align-items-center">
          <h5 className="text-white me-3">{gymName}</h5>
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle color="transparent">
              <img
                src={loggedInUser.gender === "Female" ? female : male}
                alt="profile"
                className="rounded-circle"
                width="30"
              />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem header>{loggedInUser?.name || ""}</DropdownItem>
              <DropdownItem><NavItem>
                  <Link to="/myGym" className="nav-link">
                    My Gym
                  </Link>
                </NavItem></DropdownItem>
              <DropdownItem>
                <NavItem>
                  <Link to="/Settings" className="nav-link">
                    My Account
                  </Link>
                </NavItem>
              </DropdownItem>
              {loggedInUser?.name === "MEMBER" ? <DropdownItem>My Membership </DropdownItem> : <></>}
              <DropdownItem>inbox</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>
                <NavItem>
                  <Link onClick={() => {
                    localStorage.removeItem('x-access-token');
                    window.location = "/login"
                  }} to="/login" className="nav-link">
                    Logout <i style={{ marginLeft: '15%' }} className="bi bi-box-arrow-right"></i>
                  </Link>
                </NavItem>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Collapse>
    </Navbar>
  );
};

export default Header;
