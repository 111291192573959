export default {
    INIT : '/auth/init',
    ANALYTICS: 'dashboard/analytics/',
    LOGIN: "/auth/login",
    ATTENDANCE: `/membership/attendance`,
    REGISTER: '/member/register/',
    MEMBERSHIP: '/membership/create',
    PLANS: '/gym/plans',
    MEMBERS: '/membership/members',
    MEMBERSHIPHISTORY: 'membership/membership-hist',
    IMPORT: '/membership/import',
    GYM: '/gym'
}