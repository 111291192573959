import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';
import UploadComponent from './Upload';
import DownloadSample from './Download';

const Modals = ({ 
    deleteModal,
    isUploadModalOpen,
    toggleDeleteModal,
    toggleUploadModal,
    confirmDelete,
    closeUploadModal,
    gymId 
}) => {
    return (
        <>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this item ?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={confirmDelete}>Delete</Button>
                    <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={isUploadModalOpen} toggle={toggleUploadModal}>
                <ModalHeader toggle={toggleUploadModal}>Upload File</ModalHeader>
                <ModalBody style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <UploadComponent closeModal={toggleUploadModal} gymId={gymId} />
                    <div style={{ marginRight: 'auto', marginTop: 'auto' }}>
                        <DownloadSample />
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default Modals;
