import { Col, Row, CardTitle, Card, CardBody, CardSubtitle, Alert } from "reactstrap";
import {useSelector} from 'react-redux'
import SalesChart from "../components/dashboard/SalesChart";
import Overview from "../components/dashboard/overview";
import Feeds from "../components/dashboard/Feeds";
import ProjectTables from "../components/dashboard/ProjectTable";

import Blog from "../components/dashboard/Blog";
import { useEffect, useState } from "react";
import { GET } from "rest-client";
import { getLoggedInUser } from "reducers/LoginPage/reducer";
import apiendpoints from "utils/api-endpoints";

const BlogData = [
  {
    title: "January",
    subtitle: "2 comments, 1 Like",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    btnbg: "primary",
  },
  {
    title: "February",
    subtitle: "2 comments, 1 Like",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    btnbg: "primary",
  },
  {
    title: "March",
    subtitle: "2 comments, 1 Like",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    btnbg: "primary",
  },
  {
    title: "April",
    subtitle: "2 comments, 1 Like",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    btnbg: "primary",
  },
];

const Starter = () => {
  const [dashboardData, setDashboardData] = useState({})
  const loggedInUser = useSelector(getLoggedInUser)
  useEffect(() => {
    fetchDashoardData()
  }, [])

  async function fetchDashoardData() {
    try {
      if(loggedInUser.my_gyms?.length >0){
        const {data} = await GET(apiendpoints.ANALYTICS + loggedInUser.my_gyms[0]?._id)
        setDashboardData(data?.message)
        console.log(data.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      {/***Top Cards***/}

      {/***Sales & Feed***/}
      <Row>
        <Col sm="12" lg="12" xl="12" xxl="12">
          <Overview data={dashboardData} />
        </Col>
        <Col sm="6" lg="6" xl="7" xxl="8">
          <SalesChart data={dashboardData} />
        </Col>
        <Col sm="6" lg="6" xl="5" xxl="4">
          <Feeds data={dashboardData} />
        </Col>
      </Row>
      {/***Table ***/}
      <Row>
        <Col lg="12">
          <ProjectTables data={dashboardData} />
        </Col>
      </Row>
      {/***Blog Cards***/}
      <Row>
        <Card>
          <CardBody>
            <CardTitle tag="h5">Monthly Comparision</CardTitle>
            <CardSubtitle className="text-muted" tag="h6">
              Last months reports
            </CardSubtitle>
            <Row style={{marginTop: '15px'}}>
              {BlogData.map((blg, index) => (
                <Col sm="6" lg="6" xl="3" key={index}>
                  <Blog
                    title={blg.title}
                    subtitle={blg.subtitle}
                    text={blg.description}
                    color={blg.btnbg}
                  />
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Row>
    </div>
  );
};

export default Starter;
