import React, { useState } from 'react';
import {
    Row,
    Form,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardTitle,
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { POST } from 'rest-client';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import apiEndpoints from 'utils/api-endpoints';
import { getLoggedInUser } from 'reducers/LoginPage/reducer';
import { useSelector } from 'react-redux';

const RegistrationForm = () => {
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        birthday: '',
        gender: '',
        email: '',
        phoneNumber: '',
        address: '',
        profilePicture: null,
        height: '',
        weight: ''
    });

    const [fileName, setFileName] = useState('');
    const navigate = useNavigate();
    const loggedInUser = useSelector(getLoggedInUser);
    const gymId = loggedInUser?.my_gyms[0]?._id;

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profilePicture') {
            const file = files[0];
            setForm({
                ...form,
                profilePicture: file
            });
            setFileName(file ? file.name : '');
        } else {
            setForm({
                ...form,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const memberData = {
            name: `${form.firstName} ${form.lastName}`,
            email: form.email,
            address: form.address,
            dob: new Date(form.birthday).getTime(), 
            active_gym: gymId,
            pno: form.phoneNumber,
            role: "MEMBER",
            terms_accepted: true,
            only_enquiry: true,
            gender: form.gender,  
            height: form.height,
            weight: form.weight       
        };
        if (form.profilePicture) {
            memberData.profilePicture = form.profilePicture;
        }
    
        try {
                const response = await POST(apiEndpoints.REGISTER, memberData);
            setForm({
                firstName: '',
                lastName: '',
                birthday: '',
                gender: '',
                email: '',
                phoneNumber: '',
                address: '',
                profilePicture: null,
                height:'',
                weight:''
            });
            setFileName('');
            enqueueSnackbar('Registration successfull!', {variant: 'success'})
            setTimeout(() => {
                navigate('/members');
            }, 1000);

        } catch (error) {
            console.error('There was a problem with the request:', error.response ? error.response.data : error.message);
            enqueueSnackbar('There was an error while resgistering. Please try again.!', {variant: 'error'})
        }
    };
    

    const handleCancel = () => {
        setForm({
            firstName: '',
            lastName: '',
            birthday: '',
            gender: '',
            email: '',
            phoneNumber: '',
            address: '',
            profilePicture: null,
            height:'',
            weight:''
        });
        setFileName('');
    };

    return (
        <Form onSubmit={handleSubmit} style={{ background: 'white' }}>
            <Card body>
                <CardTitle tag="h2">Registration Form</CardTitle>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="profilePicture">Profile Picture</Label>
                            <Input
                                type="file"
                                id="profilePicture"
                                name="profilePicture"
                                onChange={handleChange}
                                
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="firstName">First Name</Label>
                            <Input
                                id="firstName"
                                name="firstName"
                                placeholder="First Name"
                                type="text"
                                value={form.firstName}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="lastName">Last Name</Label>
                            <Input
                                id="lastName"
                                name="lastName"
                                placeholder="Last Name"
                                type="text"
                                value={form.lastName}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="birthday">Birthday</Label>
                            <Input
                                id="birthday"
                                name="birthday"
                                placeholder="Birthday"
                                type="date"
                                value={form.birthday}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                id="email"
                                name="email"
                                placeholder="Email"
                                type="email"
                                value={form.email}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="phoneNumber">Phone Number</Label>
                            <Input
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Phone Number"
                                type="tel"
                                value={form.phoneNumber}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="gender">Gender</Label>
                            <Input
                                type="select"
                                id="gender"
                                name="gender"
                                value={form.gender}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Others">Others</option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="height">Height</Label>
                            <Input
                                id="height"
                                name="height"
                                placeholder="Height in ft"
                                type="height"
                                value={form.height}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="weight">Weight</Label>
                            <Input
                                id="weight"
                                name="weight"
                                placeholder="Weight in kg"
                                type="weight"
                                value={form.weight}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="address">Address</Label>
                            <Input
                                id="address"
                                name="address"
                                placeholder="Address"
                                type="text"
                                value={form.address}
                                onChange={handleChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
                    <Button color="primary" type="submit">Submit</Button>
                    <Button color="secondary" onClick={handleCancel} style={{ marginLeft: '10px' }}>Cancel</Button>
                </div>
            </Card>
        </Form>
    );
};

export default RegistrationForm;
