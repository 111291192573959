import { Button, Nav, NavItem, UncontrolledTooltip } from "reactstrap";
import { useSelector } from 'react-redux'
import { Link, useLocation } from "react-router-dom";
import male from "../assets/images/users/user4.jpg";
import female from "../assets/images/users/user1.jpg";
import probg from "../assets/images/bg/download.jpg";
import { useEffect, useState } from "react";
import { getLoggedInUser } from "reducers/LoginPage/reducer";
import { sidemenuItems } from "utils/constants";

const Sidebar = () => {
  const loggedInUser = useSelector(getLoggedInUser)
  const [isCollapsed, setIsCollapsed] = useState(true)
  useEffect(() => {
    const handleClickOutside = (e) => {
      const sidebarArea = document.getElementById("sidebarArea");
      if (sidebarArea &&
        e.target?.offsetParent?.classList['value']?.indexOf('showSidebar') === -1 &&
        e.target?.offsetParent?.classList['value']?.indexOf('navbar-expand-md') === -1) {
        sidebarArea.classList.remove("showSidebar");
      }
    };

    window.addEventListener("click", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const showMobilemenu = () => {
    setIsCollapsed(false)
    document.getElementById("sidebarArea").classList.add("expanded")
      document.getElementById("sidebarArea").classList.remove('collapsed')
    document.getElementById("sidebarArea").classList.remove("showSidebar");
  };

  function handleCollapse(isEntered) {
    setIsCollapsed(!isCollapsed)
    if (isEntered){
      document.getElementById("sidebarArea").classList.add("expanded")
      document.getElementById("sidebarArea").classList.remove('collapsed')
    }
    else{
      document.getElementById("sidebarArea").classList.add("collapsed")
      document.getElementById("sidebarArea").classList.remove('expanded')
    }
  }

  let location = useLocation();

  return (
    <div onMouseEnter={() => handleCollapse(true)} onMouseLeave={() => handleCollapse(false)}>
      <div className="d-flex align-items-center"></div>
      
      <div
        className="profilebg"
        style={{ background: isCollapsed ? "" : `url(${probg}) no-repeat` }}
      >
        <div className="p-3 d-flex">
          <img src={loggedInUser?.gender === "Female" ? female : male} style={{ marginTop: '20px' }} alt="user" width={isCollapsed ? "35" : "50"} className="rounded-circle" />
          <Button
            color="white"
            className="ms-auto text-white d-lg-none"
            onClick={() => showMobilemenu()}
          >
            <i className="bi bi-x"></i>
          </Button>
        </div>
        <div style={{display: isCollapsed ? 'none': 'block', maxHeight: '40px'}} className="bg-dark text-white p-2 opacity-75">{loggedInUser?.name || ""}</div>
      </div>
      <div className="p-1 mt-1">
        <Nav vertical className="sidebarNav">
          {sidemenuItems.map((navi, index) => (
            <NavItem key={index} className="sidenav-bg" href="#" id={'index123-' + index}>
              <Link

                to={navi.href}
                className={
                  location.pathname === navi.href
                    ? "active nav-link py-3"
                    : "nav-link text-secondary py-3"
                }
              >
                <i className={navi.icon}></i>
                <UncontrolledTooltip
                  placement="right"
                  target={'index123-' + index}
                >
                  {navi.title}
                </UncontrolledTooltip>
                {!isCollapsed && <span className="ms-3 d-inline-block">{navi.title}</span>}
              </Link>
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
