import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { POST } from 'rest-client';
import { excelDateToJSDate } from 'utils/helper';
import { readFileToJSON } from 'utils/helper';
import apiEndpoints from 'utils/api-endpoints';

const UploadComponent = ({ closeModal, gymId }) => {
  const [importedData, setImportedData] = useState([]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const fileType = file?.type;
    if (!file) return;

    if (
      fileType === 'text/csv' ||
      fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      let data = await readFileToJSON(file)
      data = data.map(user => {
        if(user.dob){
          user.dob = excelDateToJSDate(user.dob)
        }
        if(user.startDate){
          user.startDate = excelDateToJSDate(user.startDate)
        }
        return user
      })
      setImportedData(data)
    } else {
      enqueueSnackbar('Please upload a .csv or .xlsx file only.!', {variant: 'warning'})
    }
  };

  const handleUpload = async () => {
    if (importedData.length === 0) {
      enqueueSnackbar('Please select a valid file first!', {variant: 'warning'})
      return;
    }

    try {
      const response = await POST(apiEndpoints.IMPORT, {users: importedData, gym_id: gymId});

      if (response.status === 200) {
        enqueueSnackbar('File uploaded successfully', {variant: 'success'})
        closeModal();
      } else {
        enqueueSnackbar('An error occurred while uploading the file', {variant: 'error'})
      }
    } catch (error) {
      console.error('Upload Error:', error);
      enqueueSnackbar('Failed to upload the file. Please try again', {variant: 'error'})
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".csv, .xlsx"
        onChange={handleFileChange}
        style={{ display: 'block', marginBottom: '10px' }}
      />
      <Button color="primary" onClick={handleUpload} style={{ marginRight: '10px' }}>
        Upload
      </Button>
      <Button color="secondary" onClick={closeModal}>
        Cancel
      </Button>
    </div>
  );
};

export default UploadComponent;
