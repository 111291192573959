import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Pagination, PaginationItem, PaginationLink, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import femaleLogo from 'assets/images/logos/femaleLogo.jpg';
import maleLogo from 'assets/images/logos/maleLogo.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import { GET } from 'rest-client';
import { getLoggedInUser } from 'reducers/LoginPage/reducer';
import { useSelector } from 'react-redux';
import { membersTableColumns} from 'utils/constants';
import Modals from './Modals';
import apiendpoints from "utils/api-endpoints";
import Loader from 'layouts/loader/Loader';

const MembershipOwners = () => {
    const { pageIndex = 1 } = useParams();
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(parseInt(pageIndex, 10));
    const [searchFilter, setSearchFilter] = useState('');
    const [batchFilter, setBatchFilter] = useState('');
    const [amountFilter, setAmountFilter] = useState('');
    const [batchDropdownOpen, setBatchDropdownOpen] = useState(false);
    const [amountDropdownOpen, setAmountDropdownOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [modal, setModal] = useState(false);
    const [daysToExpiry, setDaysToExpiry] = useState('');
    const [copiedPhone, setCopiedPhone] = useState(null);
    const [expiredFilter, setExpiredFilter] = useState(false);
    const [showNoRecordsFound, setShowNoRecordsFound] = useState(false);
    const [totalPages, setTotalPages] = useState(0)
    const [loading, setLoading] = useState(true)
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [itemsPerPageDropdownOpen, setItemsPerPageDropdownOpen] = useState(false);
    const navigate = useNavigate();
    const loggedInUser = useSelector(getLoggedInUser);
    const gymId = loggedInUser?.my_gyms[0]._id;
    const toggleUploadModal = () => setIsUploadModalOpen(!isUploadModalOpen);
    const closeUploadModal = () => { setIsUploadModalOpen(false); };
    const toggleItemsPerPageDropdown = () => setItemsPerPageDropdownOpen(!itemsPerPageDropdownOpen);

    const handleCopyPhone = (phone) => {
        navigator.clipboard.writeText(phone);
        setCopiedPhone(phone);
        setTimeout(() => setCopiedPhone(null), 2000);
    };

    useEffect(() => {
        fetchData(1);
    }, [itemsPerPage]);

    const fetchData = async (pageNo) => {
        setLoading(true)
        try {
            setLoading(true);
            const result = await GET(`${apiendpoints.MEMBERS}/${gymId}?pageSize=${itemsPerPage}&pageIndex=${pageNo}`);
            setData(result?.data?.message?.users);
            setTotalPages(Math.ceil(result?.data?.message?.total / itemsPerPage))
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false)
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages && page !== currentPage) {
            setCurrentPage(page);
            fetchData(page)
        }
    };

    const handleSearchChange = (e) => {
        setSearchFilter(e.target.value);
        setCurrentPage();
    };

    const isExpiringSoon = (dateString, days) => {
        const expiryDate = new Date(dateString);
        const today = new Date();
        const timeDifference = expiryDate - today;
        const daysDifference = timeDifference / (1000 * 3600 * 24);

        if (days === '') return false;

        const daysArray = days.split(',').map(day => parseInt(day.trim(), 10));
        return daysArray.some(day => daysDifference <= day && daysDifference >= 0);
    };


    const isExpiry = (dateString) => {
        const expiryDate = new Date(dateString);
        const today = new Date();
        const timeDifference = expiryDate - today;
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        return daysDifference <= 5;
    };

    const isExpired = (dateString) => {
        const expiryDate = new Date(dateString);
        const today = new Date();
        return expiryDate < today;
    };

    const filterData = () => {
        if (!Array.isArray(data)) {
            return [];
        }
        return data.filter(item => {
            const searchTerm = searchFilter?.toLowerCase();
            const matchesSearch = !searchFilter ||
                item?.id?.toString()?.includes(searchTerm) ||
                item?.name?.toLowerCase()?.includes(searchTerm) ||
                item?.phone?.toLowerCase()?.includes(searchTerm) ||
                item?.address?.toLowerCase()?.includes(searchTerm) ||
                item?.dateOfJoin?.toLowerCase()?.includes(searchTerm) ||
                item?.dateOfExpiry?.toLowerCase()?.includes(searchTerm) ||
                item?.modeOfPayment?.toLowerCase()?.includes(searchTerm) ||
                item?.gender?.toLowerCase()?.includes(searchTerm) ||
                item?.plan?.toLowerCase()?.includes(searchTerm) ||
                item?.batch?.toLowerCase()?.includes(searchTerm) ||
                item?.totalAmount?.toString()?.includes(searchTerm) ||
                item?.paidAmount?.toString()?.includes(searchTerm) ||
                item?.balanceAmount?.toString()?.includes(searchTerm);

            const matchesBatch = !batchFilter || item.batch.toLowerCase().includes(batchFilter.toLowerCase());

            let matchesAmount;
            if (amountFilter === 'Balance') {
                matchesAmount = item.active_membership?.pending > 0;
            } else if (amountFilter === 'Paid') {
                matchesAmount = item.active_membership?.pending === 0;
            } else {
                matchesAmount = true;
            }

            const matchesDaysToExpiry = !daysToExpiry || isExpiringSoon(item.active_membership?.endDate, daysToExpiry);
            const matchesExpired = !expiredFilter || isExpired(item.dateOfExpiry);

            return matchesSearch && matchesBatch && matchesAmount && matchesDaysToExpiry && matchesExpired;
        });
    };

    useEffect(() => {
        const filteredData = filterData();
        if (filteredData.length === 0) {
            setShowNoRecordsFound(true);
        }
    }, [searchFilter, batchFilter, amountFilter, daysToExpiry, data]);

    const paginatedData = () => { return filterData() };

    const toggleDeleteModal = () => { setDeleteModal(!deleteModal); };

    const handleDelete = (id) => { setSelectedId(id); toggleDeleteModal(); };

    const handleBatchFilter = (batch) => { setBatchFilter(batch); setCurrentPage(1); };

    const handleAmountFilter = (amount) => {
        setAmountFilter(amount); setCurrentPage(1);
    };

    const confirmDelete = () => {
        fetch(`https://api.example.com/memberships/${selectedId}`, { method: 'DELETE' })
            .then(response => response.json())
            .then(() => {
                setData(data.filter(item => item.id !== selectedId));
                toggleDeleteModal();
            })
            .catch(error => {
                console.error('Error deleting item:', error);
                toggleDeleteModal();
            });
    };

    const handleDaysToExpiryChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setDaysToExpiry(value);
        }
    };
    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
        setItemsPerPageDropdownOpen(false);
    };


    // const handleItemsPerPageChange = (e) => {
    //     const value = parseInt(e.target.value, 10);
    //     if (value > 0 && value <= 50) {
    //         setItemsPerPage(value);
    //         setCurrentPage(1);
    //     }
    // };

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>Members</h2>

                <div className="form-check form-check-inline align-items-center">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={expiredFilter}
                        onChange={() => setExpiredFilter(!expiredFilter)}
                        id="expiredCheckbox"
                    />
                    <label className="form-check-label ms-2" htmlFor="expiredCheckbox">Expired</label>
                    <Button color="primary" onClick={toggleUploadModal} className="ms-3">
                        Upload
                    </Button>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex align-items-center">
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Input
                            type="text"
                            placeholder="Search..."
                            value={searchFilter}
                            onChange={handleSearchChange}
                        />
                        <Input
                            type="text"
                            placeholder="Days to expiry"
                            value={daysToExpiry}
                            onChange={handleDaysToExpiryChange}
                            style={{ width: '4cm', height: '1cm' }}
                        />
                        <Dropdown isOpen={batchDropdownOpen} toggle={() => setBatchDropdownOpen(!batchDropdownOpen)}>
                            <DropdownToggle caret color="primary" style={{ width: '3cm', height: '1cm' }}>
                                {batchFilter || 'Batch'}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => handleBatchFilter('morning')}>Morning</DropdownItem>
                                <DropdownItem onClick={() => handleBatchFilter('evening')}>Evening</DropdownItem>
                                <DropdownItem onClick={() => handleBatchFilter('')}>Clear</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown isOpen={amountDropdownOpen} toggle={() => setAmountDropdownOpen(!amountDropdownOpen)}>
                            <DropdownToggle caret color="primary" style={{ width: '3cm', height: '1cm' }}>
                                {amountFilter || 'Amount'}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => handleAmountFilter('Balance')}>Balance Amount</DropdownItem>
                                <DropdownItem onClick={() => handleAmountFilter('Paid')}>Paid Amount</DropdownItem>
                                <DropdownItem onClick={() => handleAmountFilter('')}>Clear</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Button
                            color="primary"
                            style={{ width: '5cm', height: '1cm' }}
                            onClick={() =>
                                navigate(`/register`)}>
                            +AddMember
                        </Button>
                    </div>
                </div>
            </div>
            <Card>
                <CardBody>
                    <div style={{ position: 'relative', zIndex: 0 }}>
                        <Table className="no-wrap mt-3 align-middle" responsive borderless>
                            <thead>
                                <tr>
                                    {membersTableColumns.map(column =>
                                        <th className="column-width">{column}</th>
                                    )}
                                </tr>
                            </thead>
                            {loading ? (
                                Loader
                            ) : (
                                <>
                                    <tbody>
                                        {paginatedData().map((item) => (
                                            <tr key={item?.active_membership?.memberId} className="border-top">
                                                <td className="column-width">
                                                    <img
                                                        src={item?.gender?.toLowerCase() === 'female' ? femaleLogo : maleLogo}
                                                        alt="gender logo"
                                                        className={`gender-logo ${item?.gender?.toLowerCase() === 'female' ? 'female-logo' : 'male-logo'}`}
                                                    />
                                                    <span className="id-spacing">{item.active_membership?.memberId}</span>
                                                </td>
                                                <td style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={() => navigate(`/EditUser/${item._id}`)}>{item.name}</td>
                                                <td
                                                    className={`phone-cell ${copiedPhone === item.pno ? 'copied' : ''}`}
                                                    onClick={() => handleCopyPhone(item.pno)} >
                                                    {item.pno}
                                                    <i
                                                        className="bi bi-clipboard"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCopyPhone(item.pno);
                                                        }}
                                                    ></i>
                                                    {copiedPhone === item.pno && <span className="copied-message">Copied</span>}</td>
                                                <td className="column-width" style={{ color: isExpiry(item.active_membership?.endDate) ? 'red' : 'inherit' }}>
                                                    {item.active_membership?.endDate ? new Date(item.active_membership?.endDate).toLocaleDateString() : "--"}
                                                </td>
                                                <td>{item?.active_membership?.plan?.price - item.active_membership?.discount || '--'}</td>
                                                <td>{item?.active_membership?.payment?.pricePaid || '--'}</td>
                                                <td className="column-width" style={{ color: item.active_membership?.pending > 0 ? 'red' : 'inherit' }}>
                                                    {item.active_membership?.pending ? item.active_membership?.pending : "--"}
                                                </td>
                                                <td>{item?.active_membership?.discount || '--'}</td>
                                                <td>{item?.active_membership?.batch || '--'}</td>
                                                <td>{item?.active_membership?.plan?.name || '--'}</td>
                                                <td>{item.gender || '--'}</td>
                                                <td>{item.height || '--'}</td>
                                                <td>{item.weight || '--'}</td>
                                                <td>{item.address || '--'}</td>
                                                <td>{item.active_membership?.startDate ? new Date(item.active_membership?.startDate).toLocaleDateString() : "--"}</td>
                                                <td>{item.active_membership?.payment?.receivedType || '--'}</td>
                                                <td>
                                                    <Button
                                                        color="primary"
                                                        size="sm"
                                                        className="me-2"
                                                        onClick={() => navigate(`/Attendance/${item._id}`)}
                                                    ><i className="bi bi-calendar-check"></i>
                                                    </Button>
                                                    <Button
                                                        color="success"
                                                        size="sm"
                                                        className="me-2"
                                                        onClick={() =>
                                                            navigate(`/EditUser/${item._id}`)}
                                                    >
                                                        <i className="bi bi-pencil"></i>
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        onClick={() => handleDelete(item._id)}
                                                    ><i className="bi bi-trash"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </>
                            )}
                        </Table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <Pagination aria-label="Page navigation">
                            <PaginationItem disabled={currentPage === 1}>
                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                            </PaginationItem>
                            {[...Array(totalPages).keys()].map(page => (
                                <PaginationItem active={page + 1 === currentPage} key={page + 1}>
                                    <PaginationLink onClick={() => handlePageChange(page + 1)}>
                                        {page + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                            <PaginationItem disabled={currentPage === totalPages}>
                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                            </PaginationItem>
                        </Pagination>
                        <div className="d-flex align-items-center">
                            <Dropdown isOpen={itemsPerPageDropdownOpen} toggle={toggleItemsPerPageDropdown} className="dropdown-custom">
                                <DropdownToggle caret>
                                    Page: {itemsPerPage}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(num => (
                                        <DropdownItem key={num} onClick={() => handleItemsPerPageChange(num)}>
                                            {num}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Modals
                isUploadModalOpen={isUploadModalOpen}
                toggleUploadModal={toggleUploadModal}
                closeUploadModal={closeUploadModal}
                gymId="someGymId"
            />
        </div>
    );
};

export default MembershipOwners;
