import  * as XLSX from 'xlsx'

export const formatExpiryTime = (time) => {
    // Given date
    const givenDate = new Date(time);
    // Current date
    const currentDate = new Date();
    // Calculate the difference in time
    const timeDifference = givenDate - currentDate;
    // Convert time difference from milliseconds to days
    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return `${daysLeft} Day${daysLeft <=1 ? '': 's'} Left`
}

export function format24To12(timeString){
    if(!timeString) return false
    
    const [hourString, minute] = timeString?.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
}

export function readFileToJSON(file){
    return new Promise((res, rej) => {
        // Read the file using FileReader
        const reader = new FileReader();
        
        reader.onload = (e) => {
            try {
                // Parse the file data
                const binaryStr = e.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
          
                // Get the first sheet
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
          
                // Convert sheet to JSON
                const jsonData = XLSX.utils.sheet_to_json(sheet, { raw: true });
                
                // Update state with data
                console.log(jsonData);
                res(jsonData)
            } catch (error) {
                rej(error)
            }
        };
        reader.readAsBinaryString(file);
    })
}

export function excelDateToJSDate(serial) {
    if (serial === null || serial === undefined) return null;

    // Excel base date
    const excelBaseDate = new Date(Date.UTC(1900, 0, 1));
    // Excel date starts from 1 Jan 1900, so we subtract 1 day
    const msPerDay = 86400000; // Number of milliseconds in a day
    const jsDate = new Date(excelBaseDate.getTime() + (serial - 1) * msPerDay);

    // Adjust for Excel leap year bug (Excel considers 1900 as a leap year)
    if (serial >= 60) {
        jsDate.setDate(jsDate.getDate() - 1);
    }

    return jsDate;
}