import React, { useState, useEffect } from 'react';
import { Card, CardTitle, Row, Col, CardText, Input, FormGroup, Label, ButtonDropdown, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import axios from 'axios';
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from 'xlsx';
import { enqueueSnackbar } from 'notistack';

export default () => {
    const [days, setDays] = useState('');
    const [fromMonth, setFromMonth] = useState('');
    const [toMonth, setToMonth] = useState('');
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [detailedView, setDetailedView] = useState(false);
    const [cardData, setCardData] = useState({
        totalAdmissions: 0,
        activeMembers: 0,
        deactiveMembers: 0,
        renewed: 0,
        totalAmount: 0,
        amountCollected: 0,
        balanceAmount: 0,
        discountGiven: 0,
    });
    const [tableData, setTableData] = useState([]);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://your-api-endpoint.com/data', {
                    params: {
                        days,
                        fromMonth,
                        toMonth,
                        detailed: detailedView
                    }
                });
                setCardData({
                    totalAdmissions: response.data.totalAdmissions,
                    activeMembers: response.data.activeMembers,
                    deactiveMembers: response.data.deactiveMembers,
                    renewed: response.data.renewed,
                    totalAmount: response.data.totalAmount,
                    amountCollected: response.data.amountCollected,
                    balanceAmount: response.data.balanceAmount,
                    discountGiven: response.data.discountGiven,
                });
                setTableData(response.data.members);
                setFilteredTableData(response.data.members);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (days && fromMonth && toMonth) {
            fetchData();
        }
    }, [days, fromMonth, toMonth, detailedView]);

    const handleDaysChange = (e) => {
        setDays(e.target.value);
    };

    const handleDropdownSelect = (days) => {
        setDays(days);
        setDropdownOpen(false); 
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

    const handleFromMonthChange = (e) => {
        setFromMonth(e.target.value);
    };

    const handleToMonthChange = (e) => {
        setToMonth(e.target.value);
    };

    const handleCardClick = (filterType) => {
        setSelectedFilter(filterType);

        let filteredData;
        switch (filterType) {
            case 'totalAdmissions':
                filteredData = tableData.filter(item => item.totalAmount > 0);
                break;
            case 'activeMembers':
                filteredData = tableData.filter(item => item.status === 'active');
                break;
            case 'deactiveMembers':
                filteredData = tableData.filter(item => item.status === 'inactive');
                break;
            case 'renewed':
                filteredData = tableData.filter(item => item.isRenewed);
                break;
            case 'totalAmount':
                filteredData = tableData.filter(item => item.totalAmount > 0);
                break;
            case 'amountCollected':
                filteredData = tableData.filter(item => item.paidAmount > 0);
                break;
            case 'balanceAmount':
                filteredData = tableData.filter(item => item.balance > 0);
                break;
            case 'discountGiven':
                filteredData = tableData.filter(item => item.discount > 0);
                break;
            default:
                filteredData = tableData;
        }

        setFilteredTableData(filteredData);
    };

    const handleDownloadPDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(20);
        doc.setFont('helvetica', 'bold');

        const title = "Membership Reports";
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 20);
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(255, 0, 0);

        doc.text(`Days: ${days}`, 10, 40);
        doc.text(`From Month: ${fromMonth}`, 10, 50);
        doc.text(`To Month: ${toMonth}`, 10, 60);

        doc.setTextColor(0, 0, 0);

        doc.text(`Total Admissions: ${cardData.totalAdmissions}`, 10, 70);
        doc.text(`Active Members: ${cardData.activeMembers}`, 10, 80);
        doc.text(`Deactive Members: ${cardData.deactiveMembers}`, 10, 90);
        doc.text(`Renewed: ${cardData.renewed}`, 10, 100);
        doc.text(`Total Amount: ${cardData.totalAmount}`, 10, 110);
        doc.text(`Amount Collected: ${cardData.amountCollected}`, 10, 120);
        doc.text(`Balance Amount: ${cardData.balanceAmount}`, 10, 130);
        doc.text(`Discount Given: ${cardData.discountGiven}`, 10, 140);

        doc.autoTable({
            head: [['Member ID', 'Name', 'Phone Number', 'Total Amount', 'Paid Amount', 'Balance', 'Status', 'Expiry']],
            body: filteredTableData.map(item => [
                item.memberID,
                item.name,
                item.phoneNumber,
                item.totalAmount,
                item.paidAmount,
                item.balance,
                item.status,
                isExpiry(item.expiryDate) ? `**${item.expiryDate}**` : item.expiryDate
            ]),
            startY: 150,
        });

        doc.save('Membership Report.pdf');
    };

    const handleDownloadExcel = () => {
        console.log('Filtered Table Data:', filteredTableData);

        if (filteredTableData.length === 0) {
            enqueueSnackbar('No data available to export!', {variant: 'warning'})
            return;
        }

        const ws = XLSX.utils.json_to_sheet(filteredTableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Membership Report");

        XLSX.writeFile(wb, 'Membership_Report.xlsx');
    };

    const isExpiry = (dateString) => {
        const expiryDate = new Date(dateString);
        const today = new Date();
        const timeDifference = expiryDate - today;
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        return daysDifference <= 5;
    };

    const handleDetailedViewChange = () => {
        setDetailedView(!detailedView);
    };

    return (
        <>
            <h3>Membership Reports</h3>
           
            <Row>
            <Col md="3">
                    <FormGroup>
                        <Label for="daysInput">Number of Days</Label>
                        <div style={{ display: 'flex' }}>
                            <Input
                                type="number"
                                id="daysInput"
                                value={days}
                                onChange={handleDaysChange}
                                placeholder="Enter number of days"
                                style={{ flex: 1 }}

                            />
                            <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                            <DropdownToggle caret color="secondary">
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => handleDropdownSelect(7)}>Last Week</DropdownItem>
                                <DropdownItem onClick={() => handleDropdownSelect(30)}>Last 30 Days</DropdownItem>
                            </DropdownMenu>
                        </ButtonDropdown>
                        </div>
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label for="fromMonthSelect">From Month</Label>
                        <Input
                            type="select"
                            id="fromMonthSelect"
                            value={fromMonth}
                            onChange={handleFromMonthChange}
                        >
                            <option value="">Select From Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label for="toMonthSelect">To Month</Label>
                        <Input
                            type="select"
                            id="toMonthSelect"
                            value={toMonth}
                            onChange={handleToMonthChange}
                        >
                            <option value="">Select To Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md="3">
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" checked={detailedView} onChange={handleDetailedViewChange} />{' '}
                            Detailed View
                        </Label>
                    </FormGroup>
                    <UncontrolledDropdown>
                        <DropdownToggle caret color="primary" style={{ marginBottom: '20px' }}>
                            Download Report
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={handleDownloadPDF}>Download as PDF</DropdownItem>
                            <DropdownItem onClick={handleDownloadExcel}>Download as Excel</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
            </Row>
            {/* <Row>
            <Col md="3">
                    <FormGroup check>
                        <Label check>
                            <Input type="radio" checked={detailedView} onChange={handleDetailedViewChange} />{' '}
                            Detailed View
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
            */}

            <Row>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('totalAdmissions')}>
                        <CardTitle style={{ color: 'darkorange', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-people-fill" />{cardData.totalAdmissions}
                        </CardTitle>
                        <CardText>Total Admissions</CardText>
                    </Card>
                </Col>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('activeMembers')}>
                        <CardTitle style={{ color: 'green', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-person-check-fill" />{cardData.activeMembers}
                        </CardTitle>
                        <CardText>Active Members</CardText>
                    </Card>
                </Col>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('deactiveMembers')}>
                        <CardTitle style={{ color: 'red', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-person-x-fill" />{cardData.deactiveMembers}
                        </CardTitle>
                        <CardText>Deactive Members</CardText>
                    </Card>
                </Col>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('renewed')}>
                        <CardTitle style={{ color: 'blue', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-arrow-repeat" />{cardData.renewed}
                        </CardTitle>
                        <CardText>Renewed</CardText>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('totalAmount')}>
                        <CardTitle style={{ color: 'darkorange', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-cash-stack" />{cardData.totalAmount}
                        </CardTitle>
                        <CardText>Total Amount</CardText>
                    </Card>
                </Col>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('amountCollected')}>
                        <CardTitle style={{ color: 'green', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-currency-rupee" />{cardData.amountCollected}
                        </CardTitle>
                        <CardText>Amount Collected</CardText>
                    </Card>
                </Col>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('balanceAmount')}>
                        <CardTitle style={{ color: 'red', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-exclamation-circle-fill" />{cardData.balanceAmount}
                        </CardTitle>
                        <CardText>Balance Amount</CardText>
                    </Card>
                </Col>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('discountGiven')}>
                        <CardTitle style={{ color: 'blue', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-tags-fill" />{cardData.discountGiven}
                        </CardTitle>
                        <CardText>Discount Given</CardText>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('totalAmount')}>
                        <CardTitle style={{ color: 'darkorange', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-cash-stack" />{cardData.totalAmount}
                        </CardTitle>
                        <CardText>Expenses</CardText>
                    </Card>
                </Col>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('amountCollected')}>
                        <CardTitle style={{ color: 'green', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-currency-rupee" />{cardData.amountCollected}
                        </CardTitle>
                        <CardText>Profit</CardText>
                    </Card>
                </Col>
                <Col md="6" sm="6" lg="3">
                    <Card body className="text-center" onClick={() => handleCardClick('balanceAmount')}>
                        <CardTitle style={{ color: 'red', fontWeight: 700 }} tag="h5">
                            <i className="bi bi-exclamation-circle-fill" />{cardData.balanceAmount}
                        </CardTitle>
                        <CardText>Loss</CardText>
                    </Card>
                </Col>
            </Row>

            <Table bordered striped>
                <thead>
                    <tr>
                        <th>Member ID</th>
                        <th>Name</th>
                        <th>PhoneNumber</th>
                        <th>TotalAmount</th>
                        <th>Paid</th>
                        <th>Balance</th>
                        <th>Expiry</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData.map((item) => (
                        <tr key={item.id}>
                            <td>{item.memberID}</td>
                            <td>{item.name}</td>
                            <td>{item.totalAmount}</td>
                            <td>{item.paidAmount}</td>
                            <td>{item.balance}</td>
                            <td>
                                {isExpiry(item.expiryDate) ? (
                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{item.expiryDate}</span>
                                ) : (
                                    item.expiryDate
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};
