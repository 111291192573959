import React from 'react';
import * as XLSX from 'xlsx';

const DownloadSample = () => {
  const sampleHeaders = [
    'name',
    'email',
    'address',
    'dob',
    'paidAmount',
    'discount',
    'plan',
    'startDate',
    'paymentType',
    'pno',
    'gender',
    'batch',
  ];

  const handleDownloadXLSX = () => {
    const worksheet = XLSX.utils.aoa_to_sheet([sampleHeaders]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sample');

    XLSX.writeFile(workbook, 'sample.xlsx');
  };

  return (
    <a href="#" onClick={handleDownloadXLSX}>
      <i class="bi bi-download"></i>  
      Download Sample
    </a>
  );
};

export default DownloadSample;
