import React, { useState, useEffect } from 'react';
import { Table, Container, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { GET } from 'rest-client';
import { useSelector } from 'react-redux';
import { getLoggedInUser } from 'reducers/LoginPage/reducer';
import apiendpoints from "utils/api-endpoints";
import { useParams } from 'react-router-dom';
import { loader } from 'utils/constants';

const AttendanceHistory = () => {
    const { memberId } = useParams();
    const [attendanceData, setAttendanceData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isLoading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const loggedInUser = useSelector(getLoggedInUser);
    const gymId = loggedInUser?.my_gyms[0]?._id;

    useEffect(() => {
        if (gymId) {
            fetchAttendanceData(currentPage);
        }
    }, [gymId, currentPage]);

    const fetchAttendanceData = async (pageNo) => {
        setLoading(true);
        try {
            const response = await GET(`${apiendpoints.ATTENDANCE}?memberId=${memberId}&gymId=${gymId}&pageSize=${itemsPerPage}&pageIndex=${pageNo}`);
            if (response?.data?.success) {
                let data = response.data.message || [];
                data = data.map(item => ({
                    ...item,
                    attendanceDate: new Date(item.attendanceDate)
                }));
                data.sort((a, b) => b.attendanceDate - a.attendanceDate);

                setAttendanceData(data);
                setTotalPages(Math.ceil(data.length / itemsPerPage));
            } else {
                console.error('Failed to fetch attendance data');
            }
        } catch (error) {
            console.error('Error fetching attendance data:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateDuration = (checkIn, checkOut) => {
        if (checkIn && checkOut) {
            const checkInTime = new Date(checkIn);
            const checkOutTime = new Date(checkOut);
            const duration = (checkOutTime - checkInTime) / (1000 * 60); 
            return `${Math.floor(duration)} min`;
        }
        return 'N/A';
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, attendanceData.length);
    const paginatedData = attendanceData.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages && page !== currentPage) {
            setCurrentPage(page);
        }
    };

    return (
        <Container>
            <h3 className="mb-3">Attendance History</h3>
            {isLoading ? (
                loader
            ) : (
                <>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Login</th>
                                <th>Logout</th>
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.length > 0 ? (
                                paginatedData.map((item) => (
                                    <tr key={item._id}>
                                        <td>{item.attendanceDate.toLocaleDateString()}</td>
                                        <td>{new Date(item.checkInTime).toLocaleTimeString()}</td>
                                        <td>{item.checkOutTime ? new Date(item.checkOutTime).toLocaleTimeString() : 'N/A'}</td>
                                        <td>{calculateDuration(item.checkInTime, item.checkOutTime)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Pagination>
                        <PaginationItem disabled={currentPage === 1}>
                            <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                        </PaginationItem>
                        {[...Array(totalPages)].map((_, i) => (
                            <PaginationItem active={i + 1 === currentPage} key={i}>
                                <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                    {i + 1}
                                </PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem disabled={currentPage === totalPages}>
                            <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                        </PaginationItem>
                    </Pagination>
                </>
            )}
        </Container>
    );
};

export default AttendanceHistory;
