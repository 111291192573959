import {
  reducer,
  GET_LOCATIONS,
  GET_LOCATIONS_FAILURE,
  GET_LOCATIONS_SUCCESS,
  GET_CAROUSEL,
  GET_CAROUSEL_FAILURE,
  GET_CAROUSEL_SUCCESS,
  GET_SERVICES,
  GET_SERVICES_FAILURE,
  GET_SERVICES_SUCCESS,
  SAVE_CURRENT_LOCATION,
  SAVE_DELIVERY_SERVICE_TYPE,
  SHOW_NOTIFICATION,
  GET_TOP_RESTAURANTS,
  GET_TOP_RESTAURANTS_FAILURE,
  GET_TOP_RESTAURANTS_SUCCESS
} from './constants';

const initialState = {
  loading: false,
  locationsList: [],
  servicesList: [],
  carouselList: [],
  topList: [],
  currentLocation: {},
  deliveryService: {},
  notification: undefined
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_LOCATIONS_FAILURE:
    case GET_SERVICES_FAILURE:
    case GET_CAROUSEL_FAILURE:
    case GET_TOP_RESTAURANTS_FAILURE:
      return { ...state, loading: false };

    case GET_LOCATIONS:
    case GET_CAROUSEL:
    case GET_SERVICES:
    case GET_TOP_RESTAURANTS:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATIONS_SUCCESS: {
      var isDefault = payload.find((loc) => loc.isDefault);
      // console.log(isDefault,state.currentLocation,'kkkkkkkkkkkkkk')
      if(state?.currentLocation?.name) isDefault = state?.currentLocation;
      // console.log(isDefault,state.currentLocation,'0000000000000000000000000000000')
      return {
        ...state,
        currentLocation:
          Object.keys(state.currentLocation)?.length > 0
            ? state.currentLocation
            : isDefault
              ? [isDefault]
              : {},
        locationsList: payload,
        loading: false,
      };
    }
    case GET_SERVICES_SUCCESS: {
      return {
        ...state,
        servicesList: payload,
        loading: false,
      };
    }
    case GET_CAROUSEL_SUCCESS: {
      return {
        ...state,
        carouselList: payload,
        loading: false,
      };
    }
    case GET_TOP_RESTAURANTS_SUCCESS: {
      return {
        ...state,
        topList: payload,
        loading: false,
      };
    }
    case SAVE_CURRENT_LOCATION:
      return { ...state, currentLocation: payload };
    case SHOW_NOTIFICATION:
      return { ...state, notification: payload };
    case SAVE_DELIVERY_SERVICE_TYPE:
      return { ...state, deliveryService: payload };

    default:
      return state;
  }
};

export const getlocationsList = (state) => {
  return state[reducer].locationsList;
};
export const getcarouselList = (state) => {
  return state[reducer].carouselList;
};
export const getservicesList = (state) => {
  return state[reducer].servicesList;
};
export const activeLocation = (state) => {
  return state[reducer].currentLocation;
};

export const getDeliveryServiceType = (state) => {
  return state[reducer].deliveryService;
};

export const isLoading = (state) => {
  return state[reducer].loading;
};
export const isLoadingCarousel = (state) => {
  return state[reducer].loading;
};
export const getNotification = (state) => {
  return state[reducer].notification;
};
export const getTopRestaurantsList = (state) => {
  return state[reducer].topList;
};

export { reducer };