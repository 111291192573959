import React, { useState, useEffect } from 'react';
import {
    CardBody, CardTitle, Card, CardSubtitle, CardText, Button, Col, Row,
    UncontrolledAccordion, NavItem, Spinner
} from 'reactstrap';
import { POST, GET } from 'rest-client';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import male from "../../assets/images/users/user2.jpg";
import { getLoggedInUser } from 'reducers/LoginPage/reducer';
import MembershipDetails from './MembershipDetails';
import AttendanceHistory from './AttendanceHistory';
import MembershipHistory from './MembershipHistory';
import { enqueueSnackbar } from 'notistack';
import apiendpoints from "utils/api-endpoints";

const MembershipPage = () => {
    const { memberId } = useParams();
    const [userDetails, setUserDetails] = useState({
        name: '',
        img: '',
        phone: '',
        address: '',
        plan_name: '',
        joinedDate: '',
        discount_amount: '',
        status: '',
        batch: ''
    });
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [amount, setAmount] = useState(0);
    const [personalTraining, setPersonalTraining] = useState('');
    const [joinDate, setJoinDate] = useState(() => new Date().toISOString().split('T')[0]);
    const [discount, setDiscount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [paymentMode, setPaymentMode] = useState('');
    const [TimeSlot, setTimeSlot] = useState('');
    const [amountPaid, setAmountPaid] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false);
    const [activeTab, setActiveTab] = useState('details');
    const [loading, setLoading] = useState(false)
    const loggedInUser = useSelector(getLoggedInUser);
    const gymId = loggedInUser?.my_gyms[0]?._id;
    const { name, img, phone, address, status, batch } = userDetails;

    useEffect(() => {
        fetchUserData();
        fetchPlans();
    }, [memberId]);

    const fetchUserData = async () => {
        setLoading(true)
        try {
            const response = await GET(`${apiendpoints.REGISTER}${memberId}`);
            const data = response?.data?.message;

            const fetchedJoinDate = data?.active_membership?.startDate
                ? new Date(data.active_membership.startDate).toISOString().split('T')[0]
                : new Date().toISOString().split('T')[0];

            const paymentDetails = data?.active_membership?.payment || {};
            const activePlan = data?.active_membership?.plan || {};
            const paymentType = paymentDetails.receivedType || '';

            setUserDetails({
                name: data.name,
                img: data.img,
                phone: data.pno,
                address: data.address,
                plan_name: activePlan?.name || '',
                joinedDate: fetchedJoinDate,
                discount_amount: data?.active_membership?.discount || 0,
                status: data?.active_membership?.status,
                batch: data?.active_membership?.batch
            });

            setJoinDate(fetchedJoinDate);
            setSelectedPlan(activePlan?._id || '');
            setAmount(activePlan?.price || 0);
            setPaymentMode(paymentType);
            setAmountPaid(paymentDetails.pricePaid || 0);
            setDiscount(data?.active_membership?.discount || 0);
            setTimeSlot(data?.active_membership?.batch);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
        setLoading(false)
    };

    const fetchPlans = async () => {
        setLoading(true)
        try {
            const response = await GET(`${apiendpoints.PLANS}/${gymId}`);
            if (response.data && Array.isArray(response?.data.message)) {
                setPlans(response?.data.message);
            } else {
                console.error('Unexpected response structure for plans:', response.data);
            }
        } catch (error) {
            console.error('Error fetching plans:', error);
        }
        setLoading(false)
    };

    useEffect(() => {
        let total = (amount + (personalTraining === 'yes' ? 5000 : 0)) - discount;
        setTotalAmount(total);
    }, [amount, discount, personalTraining]);

    const handlePlanChange = (e) => {
        const selectedPlanId = e.target.value;
        const selectedPlan = plans.find((plan) => plan._id === selectedPlanId);

        if (selectedPlan) {
            setSelectedPlan(selectedPlanId);
            setAmount(selectedPlan.price);
        } else {
            setSelectedPlan('');
            setAmount(0);
        }
    };

    const handleUpdate = async () => {
        const updatedDetails = {
            gym: gymId,
            user: memberId,
            plan: selectedPlan,
            startDate: new Date(joinDate).getTime(),
            discount,
            batch: TimeSlot,
            payment: {
                status: "SUCCESS",
                response: "",
                pricePaid: amountPaid,
                receivedType: paymentMode === "upi" ? "UPI" : "CASH",
                extraCharges: 0,
                pending: amount - amountPaid
            }
        };
        setLoading(true)
        try {
            const response = await POST(apiendpoints.MEMBERSHIP, updatedDetails);
            enqueueSnackbar('Membership successfully updated!', { variant: 'success' });
            setIsEditMode(false);
            console.log('Membership details updated successfully:', response.data);
        } catch (error) {
            console.error('Error updating membership details:', error.response ? error?.response.data : error.message);
        }
        setLoading(false)
    };



    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner style={{ width: '2rem', height: '2rem' }} color="primary" />
            </div>
        );
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="me-3 text-primary">Membership</h3>
                <div className="d-flex align-items-center">
                    <NavItem className="me-3" style={{ listStyle: 'none' }}>
                        <Link
                            className={`nav-link ${activeTab === 'details' ? 'text-primary' : 'text-secondary'}`}
                            onClick={() => setActiveTab('details')}
                        >
                            Membership Details
                        </Link>
                    </NavItem>
                    <NavItem className="me-3" style={{ listStyle: 'none' }}>
                        <Link
                            className={`nav-link ${activeTab === 'attendance' ? 'text-primary' : 'text-secondary'}`}
                            onClick={() => setActiveTab('attendance')}
                        >
                            Attendance History
                        </Link>
                    </NavItem>
                    <NavItem style={{ listStyle: 'none' }}>
                        <Link
                            className={`nav-link ${activeTab === 'payment' ? 'text-primary' : 'text-secondary'}`}
                            onClick={() => setActiveTab('payment')}
                        >
                            Membership History
                        </Link>
                    </NavItem>
                </div>
            </div>
            <Row>
                <Col md={4} lg={4}>
                    <Card>
                        <Row noGutters>
                            <Col xs={4} className="d-flex align-items-center p-2" style={{ justifyContent: 'center' }}>
                                <img
                                    src={male}
                                    className="rounded-circle"
                                    alt="avatar"
                                    width="130"
                                    height="130"
                                />
                            </Col>
                            <Col xs={8}>
                                <CardBody>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <CardTitle tag="h5">{name || 'Name not available'}</CardTitle>
                                        <Button
                                            style={{
                                                cursor: 'pointer',
                                                marginLeft: '10px',
                                                backgroundColor: isEditMode ? 'red' : 'green',
                                                borderColor: isEditMode ? 'darkred' : 'darkgreen'
                                            }}
                                            onClick={() => setIsEditMode(!isEditMode)}
                                        >
                                            <i
                                                className={`bi ${isEditMode ? 'bi-x-square' : 'bi-pencil'}`}
                                            ></i>
                                        </Button>
                                    </div>
                                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                                        {phone || 'Phone number not available'}
                                    </CardSubtitle>
                                    <CardText>{address || 'Address not available'}</CardText>
                                    <CardText style={{ fontWeight: 'bold', color: status === 'ACTIVE' ? 'green' : 'red', }}>
                                        {status}
                                    </CardText>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    {activeTab === 'attendance' && (
                        <Card>
                            <CardBody>
                                <AttendanceHistory />
                            </CardBody>
                        </Card>
                    )}
                    {activeTab === 'payment' && (
                        <Card>
                            <CardBody>
                                <MembershipHistory />
                            </CardBody>
                        </Card>
                    )}
                    {activeTab === 'details' && (
                        <UncontrolledAccordion defaultOpen={['1', '2']} stayOpen>
                            <MembershipDetails
                                plans={plans}
                                selectedPlan={selectedPlan}
                                amount={amount}
                                personalTraining={personalTraining}
                                setPersonalTraining={setPersonalTraining}
                                TimeSlot={TimeSlot}
                                joinDate={joinDate}
                                discount={discount}
                                setSelectedPlan={setSelectedPlan}
                                setAmount={setAmount}
                                setTimeSlot={setTimeSlot}
                                setJoinDate={setJoinDate}
                                setDiscount={setDiscount}
                                isEditMode={isEditMode}
                                isActive={status === 'ACTIVE'}
                                handlePlanChange={handlePlanChange}
                                amountPaid={amountPaid}
                                paymentMode={paymentMode}
                                totalAmount={totalAmount}
                                batch={batch}
                                setAmountPaid={setAmountPaid}
                                setPaymentMode={setPaymentMode}
                                handleUpdate={handleUpdate}
                                setIsEditMode={setIsEditMode}
                            />

                        </UncontrolledAccordion>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default MembershipPage;