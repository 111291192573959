import React, { useState, useEffect } from 'react';
import {
    Table, Button, Input, Pagination, PaginationItem, PaginationLink, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Expenses = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [showNoRecordsFound, setShowNoRecordsFound] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [expenseFormData, setExpenseFormData] = useState({
        title: '',
        description: '',
        expenseType: 'Salary',
        date: new Date().toISOString().split('T')[0],
        modeOfPayment: 'UPI',
        amountPaid: ''
    });
    const itemsPerPage = 20;

    const toggle = () => setModal(!modal);

    const handleInputChange = (e) => {
        setExpenseFormData({
            ...expenseFormData,
            [e.target.name]: e.target.value
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            // API call to submit the expense data
            const response = await fetch('https://api.example.com/expenses', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(expenseFormData)
            });

            if (response.ok) {
                const newData = await response.json();
                setData([...data, newData]); // Update table data
                toggle(); // Close the modal after submission
            } else {
                console.error('Error submitting data');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchData = async () => {
        const dummyData = [
            {
                id: 1,
                title: 'Office Supplies',
                description: 'Purchase of office supplies',
                expenseType: 'Equipment',
                date: '2024-08-15',
                amountPaid: '150.00',
                modeOfPayment: 'UPI'
            },
            {
                id: 2,
                title: 'Monthly Salary',
                description: 'Employee salary for August',
                expenseType: 'Salary',
                date: '2024-08-01',
                amountPaid: '2000.00',
                modeOfPayment: 'Cash'
            },
            {
                id: 3,
                title: 'Marketing Campaign',
                description: 'Ads on social media',
                expenseType: 'Other',
                date: '2024-08-10',
                amountPaid: '500.00',
                modeOfPayment: 'UPI'
            },
            {
                id: 4,
                title: 'Software Subscription',
                description: 'Annual subscription to project management tool',
                expenseType: 'Other',
                date: '2024-08-20',
                amountPaid: '1200.00',
                modeOfPayment: 'UPI'
            },
            {
                id: 5,
                title: 'Team Building Event',
                description: 'Cost for the team-building activity',
                expenseType: 'Equipment',
                date: '2024-08-12',
                amountPaid: '800.00',
                modeOfPayment: 'Cash'
            },
            {
                id: 6,
                title: 'Client Lunch',
                description: 'Lunch with a potential client',
                expenseType: 'Other',
                date: '2024-08-05',
                amountPaid: '100.00',
                modeOfPayment: 'Cash'
            },
            {
                id: 7,
                title: 'Office Rent',
                description: 'Monthly rent for office space',
                expenseType: 'Other',
                date: '2024-08-01',
                amountPaid: '3000.00',
                modeOfPayment: 'UPI'
            },
            {
                id: 8,
                title: 'Printer Maintenance',
                description: 'Service and maintenance of office printer',
                expenseType: 'Equipment',
                date: '2024-08-18',
                amountPaid: '250.00',
                modeOfPayment: 'UPI'
            },
            {
                id: 9,
                title: 'Travel Expenses',
                description: 'Travel expenses for business trip',
                expenseType: 'Other',
                date: '2024-08-07',
                amountPaid: '350.00',
                modeOfPayment: 'UPI'
            },
            {
                id: 10,
                title: 'Internet Bill',
                description: 'Monthly internet bill for office',
                expenseType: 'Other',
                date: '2024-08-16',
                amountPaid: '60.00',
                modeOfPayment: 'Cash'
            }
        ];
        setData(dummyData);
        // try {
        //     const response = await fetch('https://api.example.com/expenses');
        //     const result = await response.json();
        //     setData(result);
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const filterData = () => {
        return data.filter(item => {
            const searchTerm = searchFilter.toLowerCase();
            return !searchFilter ||
                item.title.toLowerCase().includes(searchTerm) ||
                item.description.toLowerCase().includes(searchTerm) ||
                item.expenseType.toLowerCase().includes(searchTerm) ||
                item.date.toLowerCase().includes(searchTerm) ||
                item.modeOfPayment.toLowerCase().includes(searchTerm) ||
                item.amountPaid.toString().includes(searchTerm);
        });
    };

    useEffect(() => {
        const filteredData = filterData();
        if (filteredData.length === 0) {
            setShowNoRecordsFound(true);
            const timer = setTimeout(() => setShowNoRecordsFound(false), 4000);
            return () => clearTimeout(timer);
        }
    }, [data]);

    const paginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filterData().slice(startIndex, startIndex + itemsPerPage);
    };

    const totalPages = Math.ceil(filterData().length / itemsPerPage);

    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };

    const handleDelete = (id) => {
        setSelectedId(id);
        toggleDeleteModal();
    };

    const confirmDelete = async () => {
        try {
            await fetch(`https://api.example.com/expenses/${selectedId}`, { method: 'DELETE' });
            setData(data.filter(item => item.id !== selectedId));
            toggleDeleteModal();
        } catch (error) {
            console.error('Error deleting item:', error);
            toggleDeleteModal();
        }
    };

    const today = new Date().toISOString().split('T')[0];

    return (
        <div>
            <h2>Expenses</h2>
            <div className="d-flex justify-content-end align-items-center mb-3">
                <Button
                    color="success"
                    style={{ width: '4cm', height: '1cm' }}
                    onClick={toggle}
                >
                    +Add Expenses
                </Button>
            </div>
            <Card style={{ width: '100%' }}>
                <CardBody>
                    <div style={{ overflowX: 'auto' }}>
                        <Table className="no-wrap mt-3 align-middle" responsive borderless>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Expense Type</th>
                                    <th>Date</th>
                                    <th>Paid Amount</th>
                                    <th>Mode Of Payment</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData().map((item) => (
                                    <tr key={item.id} className="border-top">
                                        <td>{item.title}</td>
                                        <td>{item.description}</td>
                                        <td>{item.expenseType}</td>
                                        <td>{item.date}</td>
                                        <td>{item.amountPaid}</td>
                                        <td>{item.modeOfPayment}</td>
                                        <td>
                                            <Button
                                                color="success"
                                                size="sm"
                                                className="me-2"
                                                onClick={() => window.location.href = `/#/expenseDetails/${item.id}`}
                                            >
                                                <i className="bi bi-pencil"></i>
                                            </Button>
                                            <Button
                                                color="danger"
                                                size="sm"
                                                onClick={() => handleDelete(item.id)}
                                            ><i className="bi bi-trash"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                                {/* {showNoRecordsFound && (
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            No records found
                                        </td>
                                    </tr>
                                )} */}
                            </tbody>
                        </Table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                        <Pagination>
                            <PaginationItem disabled={currentPage <= 1}>
                                <PaginationLink first onClick={() => handlePageChange(1)} />
                            </PaginationItem>
                            <PaginationItem disabled={currentPage <= 1}>
                                <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                            </PaginationItem>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <PaginationItem active={i + 1 === currentPage} key={i}>
                                    <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                        {i + 1}
                                    </PaginationLink>
                                </PaginationItem>
                            ))}
                            <PaginationItem disabled={currentPage >= totalPages}>
                                <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                            </PaginationItem>
                            <PaginationItem disabled={currentPage >= totalPages}>
                                <PaginationLink last onClick={() => handlePageChange(totalPages)} />
                            </PaginationItem>
                        </Pagination>
                    </div>
                </CardBody>
            </Card>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this item?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={confirmDelete}>Delete</Button>
                    <Button color="secondary" onClick={toggleDeleteModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add Expense</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleFormSubmit}>
                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Input
                                type="text"
                                name="title"
                                value={expenseFormData.title}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input
                                type="text"
                                name="description"
                                value={expenseFormData.description}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="expenseType">Expense Type</Label>
                            <Input
                                type="select"
                                name="expenseType"
                                value={expenseFormData.expenseType}
                                onChange={handleInputChange}
                            >
                                <option>Salary</option>
                                <option>Equipment</option>
                                <option>Other</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="date">Date</Label>
                            <Input
                                type="date"
                                name="date"
                                value={expenseFormData.date}
                                onChange={handleInputChange}
                                max={today}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="modeOfPayment">Mode of Payment</Label>
                            <Input
                                type="select"
                                name="modeOfPayment"
                                value={expenseFormData.modeOfPayment}
                                onChange={handleInputChange}
                            >
                                <option>UPI</option>
                                <option>Cash</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="amountPaid">Amount Paid</Label>
                            <Input
                                type="number"
                                name="amountPaid"
                                value={expenseFormData.amountPaid}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit"> Submit</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default Expenses;
