import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  CardSubtitle,
  ListGroupItem,
  Button,
} from "reactstrap";
import { formatExpiryTime } from "utils/helper";
import { useNavigate } from "react-router-dom";

const Feeds = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = (userId) => {
    navigate(`/EditUser/${userId}`);
  };

  return (
    <Card style={{ minHeight: '94%', maxHeight: '94%' }}>
      <CardBody>
        <CardTitle tag="h5">Membership Expiries</CardTitle>
        <CardSubtitle className="mb-2 text-muted" tag="h6">
          Upcoming Membership Expiries
        </CardSubtitle>
        <ListGroup flush className="mt-4" style={{ maxHeight: '23em', overflowY: 'auto' }}>
          {data?.sortedByEndDate?.map((feed, index) => (
            <ListGroupItem
              key={index}
              action
              tag="a"
              onClick={() => handleClick(feed._id)} 
              style={{ background: index <= 1 ? 'lightcoral' : 'white', border: '0.1px dashed lightgrey' }}
              className="d-flex align-items-center p-3"
            >
              <Button
                className="rounded-circle me-3"
                size="sm"
                color={feed?.color || "danger"}
              >
                <i className={"bi-bell"}></i>
              </Button>
              <span style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>{feed?.user?.name || feed.memberId}</span>
              <small className="ms-auto text-muted text-small">
                {new Date(feed.endDate).toLocaleDateString()}
              </small>
              <small className="ms-auto text-muted text-small">
                {formatExpiryTime(feed.endDate)}
              </small>
            </ListGroupItem>
          ))}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default Feeds;
