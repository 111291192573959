import LogoDark from "../assets/logo/transparent.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to="/">
      <img
              src={LogoDark}
              alt="profile"
              // className="rounded-circle"
              style={{borderRadius: 8}}
              width="60"
            ></img>
    </Link>
  );
};

export default Logo;
