import { Card, CardTitle, Row, Col, CardText } from "reactstrap";

const Tile = ({
  amount,
  title,
  color,
  icon = "currency-rupee"
}) => {
  return (
    <Col md="6" sm="6" lg="3">
        <Card body className="text-center">
          <CardTitle style={{ color, fontWeight: 700 }} tag="h5"><i style={{marginRight: '5px'}} className={`bi bi-${icon}`} />{amount || 0}</CardTitle>
          <CardText>
            {title}
          </CardText>
        </Card>
      </Col>
  )
}

export default ({ data }) => {
  return (
    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Tile amount={data?.totalAmount} title="Total Amount" color="purple" />
      <Tile amount={data?.collected} title="Collected" color="green" />
      <Tile amount={data?.pending} title="Balance" color="red" />
      <Tile amount={data?.discount} title="Discount" color="grey" />
      <Tile amount={data?.totalMembers} title="Total Admissions" color="darkorange" icon="people" />
      <Tile amount={data?.activeMembers} title="Active Members" color="green" icon="person-fill-check" />
      <Tile amount={data?.expired} title="Expired" color="red" icon="people" />
      <Tile amount={data?.today_logins} title="Today Logins" color="blue" icon="people" />
    </Row>
  )
}